<template>
 	<div>
 		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
 			<el-form-item label="新密码" prop="first">
 				<el-input v-model="ruleForm.first"></el-input>
 				<!-- <el-button type="primary" @click="sendCode()">发送验证码</el-button> -->
 			</el-form-item>
 			<el-form-item label="再次输入新密码" prop="second">
 				<el-input v-model="ruleForm.second"></el-input>
 			</el-form-item>
 			<el-form-item>
 				<el-button type="primary" @click="submitForm('ruleForm')">修改密码</el-button>
 			</el-form-item>
 		</el-form>
 	</div>
 </template>

<script>
 	import qs from 'qs'
 	export default {
 		data () {
 			return {
      ruleForm: {
        first: '',
        second: ''
      },

      rules: {
 				first: [{
 						required: true,
 						message: '请输入新密码',
 						trigger: 'blur'
 					},
 					{
 						pattern: '^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$',
 						message: '至少8个字符，至少1个大写字母，1个小写字母和1个数字,不能包含特殊字符（非数字字母）',
 						trigger: 'blur'
 					}
 				],
 				second: [{
 						required: true,
 						message: '请再次输入新密码',
 						trigger: 'change'
 					},
 					{
 						pattern: '^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$',
 						message: '至少8个字符，至少1个大写字母，1个小写字母和1个数字,不能包含特殊字符（非数字字母）',
 						trigger: 'blur'
 					}
 				]
 			}
 		}
 	},
 	methods: {
 		submitForm (formName) {
 			this.$refs[formName].validate(async valid => {
 				if (valid) {
 					// alert('submit!');
 					// 预验证通过，后访问后台数据
          if (this.ruleForm.first !== this.ruleForm.first) {
            return
          }
 					// 在这里进行code的后端验证
          this.$http({
            url: 'updatePwd',
            method: 'POST',
            data: qs.stringify({ pwd: this.ruleForm.first })
          }).then(res => {
            if (res.data.code !== 200) {
              this.$message.error(res.data.message)
            } else {
              this.$message.success(res.data.message)
              this.$router.push('/login')
            }
          })
 				} else {
 					this.$message.error('请您核对输入的账号、密码等信息')
 					// console.log('error submit!!');
 					return false
 				}
 			})
 		},
 		sendCode () {
 			var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
 			if (!myreg.test(this.ruleForm.phone)) {
 				this.$message.error('请输入正确的手机号')
 			} else {
 				// this.$message.success("手机号验证通过")
 				// 进行密码的发送操作
 				this.$http({
 					url: 'smsCode',
 					method: 'POST',
 					data: qs.stringify({
 						phone: this.ruleForm.phone
 					})
 				}).then(res => {
 					console.log(res.data.code)
 					if (res.data.code !== '200') {
 						this.$message.error(res.data.message)
 					} else {
 						this.$message.success(res.data.message)
 					}
 				})
 			}
 		}
 	}
 	}
</script>

 <style scoped>

 </style>
