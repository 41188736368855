<template>
	<div class="login_continer">
		<!-- <img src="../assets/favicon.png" > -->
		<div class="login_box">
			<!-- 头像区域 -->
			<!-- <div class="avatar_box">
				<img src="../assets/logo.png">
			</div> -->
			<!-- 表单区域 -->
			<div class="peitu">
				<img  src="../assets/peitu.png" >
				<!-- <el-image
				      style="width: 100px; height: 100px"
				      src="assets/peitu.png"
				      fit="scale-down"></el-image> -->
			</div>
			<div class="box">
				<img src="../assets/logo-clr.png" >
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">

				  <el-form-item label="账号" prop="username">
				    <el-input prefix-icon="el-icon-user-solid" v-model="ruleForm.username"></el-input>
				  </el-form-item>
				  <el-form-item label="密码" prop="password">
				    <el-input prefix-icon="el-icon-s-cooperation" type="password" v-model="ruleForm.password"></el-input>
				  </el-form-item>
				  <el-form-item>
				      <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
				      <el-button @click="resetForm('ruleForm')">清空</el-button>
					 <el-button @click="forgetPassword()">重置密码</el-button>
				  </el-form-item>
				</el-form>
			</div>

		</div>
	</div>
</template>

<script>
import qs from 'qs'
export default {
	    data () {
	      return {
	        ruleForm: {
	          username: '',
	          password: ''

	        },
	        rules: {
	          username: [
	            { required: true, message: '请输入账号', trigger: 'blur' },
	            { min: 3, max: 15, message: '账号长度在 3 到 15 个字符之间', trigger: 'blur' }
	          ],
	          password: [
	            { required: true, message: '请输入密码', trigger: 'change' },
	            { min: 3, max: 15, message: '密码长度在 3 到 15 个字符之间', trigger: 'blur' }
	          ]
	        }
	      }
	    },
	    methods: {
	      submitForm (formName) {
	        this.$refs[formName].validate(async valid => {
	          if (valid) {
	            // alert('submit!');
          // 预验证通过，后访问后台数据
          const { data } = await this.$http.post('masagerLogin', qs.stringify(this.ruleForm))
          console.log(data)
          if (data.code !== 200) {
            return this.$message.error(data.message)
          }
          this.$message.success(data.message)
          // 存取token数据
          window.sessionStorage.setItem('satoken', data.data.tokenInfo.tokenValue)
          // 存取机构的id是否为会员，以及校区的数量
          window.sessionStorage.setItem('campusNum', data.data.campusNum)
          window.sessionStorage.setItem('iid', data.data.iid)
          window.sessionStorage.setItem('isIvip', data.data.isIvip)
          window.sessionStorage.setItem('unMessageList', data.data.unMessageList)
          window.sessionStorage.setItem('unMessageNum', data.data.unMessageNum)
          window.sessionStorage.setItem('sap', data.data.sap)
          // console.log(data.data.unMessageList)
          this.$router.push('/home')
	          } else {
          this.$message.error('请您核对输入的账号、密码等信息')
	            // console.log('error submit!!');
	            return false
	          }
	        })
	      },
	      resetForm (formName) {
	        this.$refs[formName].resetFields()
	      },
		  forgetPassword () {
			  // console.log("修改密码")
			  // 跳转路径
			  this.$router.push('getpassword')
		  }
	    }
	  }
</script>

<style lang="less" scoped>
	.login_continer {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background:url(../assets/backgroudd.png);
		background-size: 100% 100%;
		.login_box {
			display: flex;
			// width: 52%;
			height: 300px;
			// background-color: #fff9f7;
			border-radius: 10px;

			// .avatar_box {
			// 	position: absolute;
			// 	left: 50%;
			// 	transform: translate(-50%, -50%);
			// 	padding: 10px;
			// 	width: 130px;
			// 	height: 130px;
			// 	border: 1px solid #eee;
			// 	border-radius: 50%;
			// 	box-shadow: 0 0 10px #ddd;
			// 	background-color: #fff;

			// 	img {
			// 		width: 100%;
			// 		height: 100%;
			// 		border-radius: 50%;
			// 		background-color: #eeeeee;
			// 	}
			// }
			.peitu{
				flex: 1;
			}

			.box{
				flex: 1;
				background-color: #FFFFFF;
				border-radius: 20px;
				// img{
				// 	margin: 0 auto;
				// }
				img {
				    margin-left: auto;
				    margin-right:auto;
				    display:block;
					padding-top: 40px;
				}
			}

			.demo-ruleForm{
				box-sizing: border-box;

				// background-color: #FFFFFF;
				// position: absolute;
				bottom: 0;
				width: 100%;
				padding: 30px 45px 20px 10px;
			}
		}
	}

</style>
