<template>
	<div class="add">
		<!-- 新增校区的按钮 -->
		<el-button class="addButton" type="primary" size="medium" @click="addCampuse()">添加员工</el-button>
		<!-- 校区列表展示 -->
		<el-table :data="campuses" style="width: 100%" stripe border>
			<el-table-column label="员工账号" >
				<template slot-scope="scope">
					<i class="el-icon-time"></i>
					<span style="margin-left: 10px">{{ scope.row.username }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="校区创建时间" width="180">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.create_time | formatTimer}}</span>
				</template>
			</el-table-column>
			<el-table-column label="校区地址" width="500">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.address }}</span>
				</template>
			</el-table-column>
			<el-table-column label="校区上下架状态" width="180">
				<template slot-scope="scope">
					<el-switch v-model="scope.row.deleted===1" active-color="#ff4949" inactive-color="#13ce66"
						@change="deletedChange(scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column label="上架课程" width="180">
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.ccount}}</span>
				</template>
			</el-table-column>
			<el-table-column label="审核状态" width="180">
				<template slot-scope="scope">
					<span v-if="scope.row.is_pass === 1" style="margin-left: 10px;color: burlywood;">审核中</span>
					<span v-if="scope.row.is_pass === 2" style="margin-left: 10px;color: #42B983;">审核通过</span>
					<span v-if="scope.row.is_pass === 3" style="margin-left: 10px;color: crimson;">审核不通过</span>
				</template>
			</el-table-column> -->
			<el-table-column label="操作">
				<template slot-scope="scope">
					<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
					<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-preventReClick="3000">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

		<!-- 添加校区对话框的相关代码 -->
		<el-dialog @opened="show()" @closed="hide()" title="添加员工" :visible.sync="dialogFormVisible" destroy-on-close>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="员工账号" prop="username">
					<el-input v-model="ruleForm.username"></el-input>
				</el-form-item>
				<!-- <el-form-item label="校区地址" prop="address">
					<el-input v-model="ruleForm.address"></el-input>
				</el-form-item>
				<el-form-item label="校区简介" prop="describes">

					<div id="demo1"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly
						v-model="ruleForm.describes"></textarea>
				</el-form-item>
				<el-form-item label="校区图片" prop="image">
					<el-upload v-model="ruleForm.image" :on-success="loadSuccess" :before-remove="removeImagebefore"
						:on-remove="removeImage" :http-request="ImgUploadRequest" list-type="picture-card" action=""
						:limit=6>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hide()">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick="3000">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 修改校区对话框的相关代码 -->
		<el-dialog @opened="showup()" @closed="hideup()" title="修改校区" :visible.sync="dialogFormVisibleupdate"
			destroy-on-close>
			<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="校区名称" prop="cName">
					<el-input v-model="updateRuleForm.cName"></el-input>
				</el-form-item>
				<el-form-item label="校区地址" prop="address">
					<el-input v-model="updateRuleForm.address"></el-input>
				</el-form-item>
				<el-form-item label="校区简介" prop="describes">

					<div id="demo2"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly
						v-model="updateRuleForm.describes"></textarea>

					<!-- <el-input v-model="ruleForm.describes"></el-input> -->
				</el-form-item>
				<el-form-item label="校区图片" prop="image">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<el-upload v-model="updateRuleForm.image" :on-success="loadSuccess"
						:before-remove="removeImagebeforeup" :on-remove="removeImage" :file-list="updateImagedd"
						:http-request="ImgUploadRequestup" list-type="picture-card" action="" :limit=6>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisibleupdate">
						<!-- 在这里进已上传图片的遍历操作 -->
						<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideup()">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('updateRuleForm')">修 改</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
import wangEditor from 'wangeditor'
import OSS from 'ali-oss'
import qs from 'qs'
import {
  v4 as uuidv4
} from 'uuid'

import oss from '../../utils/oss'

export default {
  created () {
    this.queryInfo.id = window.sessionStorage.getItem('iid') * 1
    this.ruleForm.i_id = window.sessionStorage.getItem('iid') * 1
    this.getCampusList()
  },
  data () {
    return {
      iid: '',
      campuses: [],
      queryInfo: {
        id: 0
      },
      value: false,
      deletedParam: {
        id: 0
      },
      dialogFormVisible: false,
      dialogFormVisibleupdate: false,
      formLabelWidth: '120px',
      ruleForm: {
        username: '',
        i_id: ''
      },
      updateRuleForm: {
        cName: '',
        address: '',
        image: '',
        describes: '',
        isPass: 1,
        cId: 0
      },
      updateData: {
        cName: '',
        address: '',
        image: '',
        describes: '',
        isPass: 1,
        cId: 0
      },
      updateImageTemp: [], // 修改操作时新上传的图片文件暂存区
      updateImagedd: [], // 修改操作全部文件的暂存区
      rules: {
        username: [{
          required: true,
          message: '请输入员工手机号',
          trigger: 'blur'
        },
        {
          pattern: /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
          message: '请输入正确的手机号',
          trigger: 'blur'
        }
        ]
      },
      updateRules: {
        cName: [{
          required: true,
          message: '请输入校区名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }
        ],
        address: [{
          required: true,
          message: '请输入校区地址',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 25 个字符',
          trigger: 'blur'
        }
        ],
        describes: [{
          required: false,
          message: '请填写校区简介',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请至少上传一张图片',
          trigger: 'blur'
        }]
      },
      editor: null,
      dialogImageUrl: '', // 图片地址
      dialogVisible: false, // 是否允许图片
      dialogVisibleupdate: false,
      imageArray: [],
      updateImageArray: []
    }
  },

  methods: {
    addCampuse () {
      this.dialogFormVisible = true
    },
    async getCampusList () {
      const {
        data
      } = await this.$http.get('company/sys-user/allWorker', {
        params: this.queryInfo
      })
      console.log(data)
      if (data.code !== 200) {
        this.$message.error(data.message)
      }
      this.campuses = data.data
    },
    // 点击修改按钮时的方法
    handleEdit (index, row) {
      console.log(index, row)
      // 在这里进行数据的赋值操作
      this.updateRuleForm.cName = row.c_name
      this.updateRuleForm.address = row.address
      // 在这里进行数据的判断操作
      // 1重新打开页面的时候，就赋值updateImagedd里面的数值
      // 2第一次打开页面就赋值row里面的数值
      // if(row.image.length !== this.updateRuleForm.image.length){
      // 	// 第一次打开
      // 	this.updateRuleForm.image =this.updateRuleForm.image
      // }else{
      // 	this.updateRuleForm.image = row.image
      // }
      this.updateRuleForm.image = row.image
      this.updateRuleForm.describes = row.describes
      this.updateRuleForm.cId = row.c_id
      this.updateRuleForm.is_vip = row.is_vip
      this.updateRuleForm.i_id = row.i_id
      // this.updateImagedd = []
      // console.log(this.updateImagedd)
      // 进行图片列表的封装操作
      const imgArr = this.updateRuleForm.image.split(',')
      imgArr.forEach(item => {
        const obj = {
          url: item
        }
        this.updateImagedd.push(obj)
      })
      // 打开弹出框
      this.dialogFormVisibleupdate = true
    },
    handleDelete (index, row) {
      // console.log(index, row);
      // console.log("点击了删除按钮")
      this.$http({
        url: 'company/sys-user/deleteWork',
        method: 'POST',
        params: { username: row.username }
      }).then(res => {
        // console.log(res)
        if (res.data.code === 200) {
          this.$message.success(res.data.message)
          this.getCampusList()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    async deletedChange (campusesInfo) {
      // console.log(campusesInfo)
      // false的时候，deleted为0    true的时候deleted为1
      // 进行商品的上下架操作
      this.deletedParam.id = campusesInfo.c_id
      console.log(this.deletedParam.id)
      if (campusesInfo.deleted === 0) {
        // 下架
        const {
          data
        } = await this.$http.post('company/campus/deleteCampus', qs.stringify(this.deletedParam))
        // console.log(data)
        if (data.code !== 200) {
          return this.$message.error('操作失败，校区未成功下架')
        }
        campusesInfo.deleted = 1
        return this.$message.success('操作成功，校区已成功下架')
        // 这个时候修改元数组
      } else {
        // 上架
        const {
          data
        } = await this.$http.post('company/campus/undeleteCampus', qs.stringify(this.deletedParam))
        // console.log(data)
        if (data.code !== 200) {
          return this.$message.error('操作失败，校区未成功上架')
        }
        campusesInfo.deleted = 0
        return this.$message.success('操作成功，校区已成功上架')
        // 这个时候修改元数组
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          // 数据提交操作
          // console.log(this.ruleForm)
          // this.$http.post('company/campus/addCampus', qs.stringify(this.ruleForm)).then(res=>{
          // 	console.log(res)
          // })
          this.$http({
            url: 'company/sys-user/addWork',
            method: 'POST',
            // header: {
            // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
            // },
            data: qs.stringify(this.ruleForm)
          }).then(res => {
            // console.log(res.data)
            // 开始进行校区的判断操作
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
            } else {
              this.$message.error(res.data.message)
            }
            // 进行弹出框的关闭操作
            this.dialogFormVisible = false
            this.ruleForm.username = ''
            this.getCampusList()
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    submitUpdateForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            url: 'company/campus/updateCampus',
            method: 'POST',
            // header: {
            // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
            // },
            data: qs.stringify(this.updateRuleForm)
          }).then(res => {
            // console.log(res.data)
            // 开始进行校区更改结果的判断操作
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              // 更新缓存数据
              if (this.updateRuleForm.is_pass === 2) {
                var campusNum = window.sessionStorage.getItem('campusNum') * 1
                campusNum = campusNum - 1
                window.sessionStorage.setItem('campusNum', campusNum)
              }
            } else {
              this.$message.error(res.data.message)
            }
            // 进行弹出框的关闭操作
            this.updateRuleForm.address = ''
            this.updateRuleForm.cName = ''
            this.updateRuleForm.image = ''
            this.updateRuleForm.describes = ''
            this.updateImagedd = []
            this.dialogFormVisibleupdate = false
            this.getCampusList()
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    beforeDestroy () {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    },
    getEditorData () {
      // 通过代码获取编辑器内容
      const data = this.editor.txt.html()
      // alert(data)
    },
    // 显示添加校区的富文本
    show () {
      const editor = new wangEditor('#demo1')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.ruleForm.describes = newHtml
      }

      const client = oss.createOssClient1()
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        client.put(uuidv4(), resultFiles[0])
          .then(function (res) {
            console.log(res)
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url)
          }).catch(function (err) {
            console.log(err)
          })
        // oss.ossUploadFile(uuidv4(), resultFiles[0])
        // 	.then(function(res) {
        // 		console.log(res)
        // 		// 上传图片，返回结果，将图片插入到编辑器中
        // 		insertImgFn(res.url)
        // 	}).catch(function(err) {
        // 		console.log(err)
        // 	})
      }

      // 创建编辑器
      editor.create()
      this.editor = editor
    },
    showup () {
      const editor = new wangEditor('#demo2')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.updateRuleForm.describes = newHtml
      }

      const client = oss.createOssClient1()
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        client.put(uuidv4(), resultFiles[0])
          .then(function (res) {
            console.log(res)
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url)
          }).catch(function (err) {
            console.log(err)
          })
      }

      // 创建编辑器
      editor.create()
      editor.txt.html(this.updateRuleForm.describes)
      this.editor = editor
    },
    // 隐藏富文本
    hide () {
      // this.$message.success("asdsadsa")
      this.dialogFormVisible = false
      this.ruleForm.address = ''
      this.ruleForm.cName = ''
      this.ruleForm.image = ''
      this.ruleForm.describes = ''
      // 批量删除oss图片
      // this.imageArray.forEach(item => {
      // 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
      // 	if (isDeleted) {
      // 		console.log("删除成功")
      // 	} else {
      // 		console.log("删除失败")
      // 	}
      // })
      this.imageArray = []
    },
    hideup () {
      // this.$message.success("asdsadsa")
      this.updateRuleForm.address = ''
      this.updateRuleForm.cName = ''
      this.updateRuleForm.image = ''
      this.updateRuleForm.describes = ''
      this.updateImagedd = []
      this.dialogFormVisibleupdate = false
      // 批量删除oss图片
      // this.updateImageTemp.forEach(item => {
      // 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
      // 	if (isDeleted) {
      // 		console.log("删除成功")
      // 	} else {
      // 		console.log("删除失败")
      // 	}
      // })
      // this.updateImageTemp = []
    },
    // 删除图片
    // handleRemove(file, fileList) {
    // 	console.log(file, fileList);
    // },
    // // 图片回显
    // handlePictureCardPreview(file) {
    // 	this.dialogImageUrl = file.url;
    // 	this.dialogVisible = true;
    // },
    async ImgUploadRequest (option) {
      // console.log("进入上传方法")
      oss.ossUploadFile(option).then(res => {
        // console.log(res.res.requestUrls)
        this.imageArray.push(res.res.requestUrls[0])
        // console.log(this.imageArray)
        this.ruleForm.image = this.imageArray.join(',')
      })
    },
    async ImgUploadRequestup (option) {
      // console.log("进入上传方法")
      oss.ossUploadFile(option).then(res => {
        // 向image中添加图片
        const obj = {
          url: res.res.requestUrls[0]
        }
        this.updateImagedd.push(obj)
        const aa = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.image = aa.join()
        console.log('##', this.updateRuleForm.image)
      })
    },
    removeImage (file, fileList) {
    },
    // 删除之前，对图片数据进行维护(添加校区)
    removeImagebefore (file, fileList) {
      // 1调用图片删除的api
      var isDeleted = oss.ossDeleteImages(file)
      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        // console.log(this.imageArray)
        this.imageArray.splice(this.imageArray.findIndex(item => item === file.response.res.requestUrls[0], 1))
        // console.log(this.imageArray)
        if (this.imageArray.length !== 0) {
          this.ruleForm.image = this.imageArray.join(',')
        } else {
          this.ruleForm.image = ''
        }
        this.$message.success('删除成功')
      } else {
        this.$message.success('删除失败')
      }
      return isDeleted
    },
    //  删除之前，对图片数据进行维护(修改校区)
    // 注意：修改校区的时候最后一张图片不能进行删除
    removeImagebeforeup (file, fileList) {
      // console.log(file.response.res.requestUrls[0])
      if (this.updateImagedd.length === 1) {
        this.$message.error('请至少保留一张图片')
        return false
      }
      // 获取删除文件的下标
      let img = this.updateImagedd.map(v => v.url)
      var Index = img.findIndex(item => item === file.url)
      // 1调用图片删除的api
      // console.log('##',fileList)
      var fileName = file.url.substr(file.url.indexOf('/image'))
      // console.log('$$',fileName)
      var isDeleted = oss.ossDeleteImage(fileName)

      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        this.updateImagedd.splice(Index, 1)
        img = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.image = img.join()
        // 进行数据库的删除操作
        this.deleteImage()
        this.getCampusList()
        return true
      } else {
        this.$message.success('删除失败')
        return false
      }
    },
    loadSuccess (file, fileList) {
      // console.log("上传成功！", file, fileList)
    },
    deleteImage () {
      this.$http({
        url: 'company/campus/updateImagesById',
        method: 'POST',
        // header: {
        // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
        // },
        data: qs.stringify({ id: this.updateRuleForm.cId, img: this.updateRuleForm.image })
      }).then(res => {
        if (res.data.code === 200) {
          this.$message.success('删除成功')
        }
        return res.data
      })
    }

  },
  // 格式化日期
  filters: {
    formatTimer: function (value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m
    }
  }

}
</script>

<style scoped lang="less">
	.addButton {
		margin-bottom: 20px;
	}

	.home {
		width: 100%;
		margin-right: 20px;
		position: relative;
	}
</style>
