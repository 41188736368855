<template>
	<div class="add">
		<!-- 新增校区的按钮 -->
		<el-button class="addButton" type="primary" size="medium" @click="addCampuse()">新增校区</el-button>
		<!-- 校区列表展示 -->
		<el-table :data="campuses" style="width: 100%" stripe border fit>
			<!-- <el-table-column label="校区名称" width="300"> -->
			<el-table-column label="校区名称">
				<template slot-scope="scope">
					<i class="el-icon-time"></i>
					<span style="margin-left: 10px">{{ scope.row.c_name }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="校区创建时间" width="180"> -->
			<el-table-column label="校区创建时间" >
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.create_time | formatTimer}}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="校区地址" width="500"> -->
			<el-table-column label="校区地址" >

				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.address }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="校区上下架状态" width="180"> -->
			<el-table-column label="校区上下架状态">
				<template slot-scope="scope">
					<!-- <span style="margin-left: 10px">{{ scope.row.deleted === 0? "上架" : "下架" }}</span> -->
					<el-switch v-model="scope.row.deleted===1" active-color="#ff4949" inactive-color="#13ce66"
						@change="deletedChange(scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<!-- <el-table-column label="上架课程" width="180"> -->
			<el-table-column label="上架课程" >
				<template slot-scope="scope">
					<span style="margin-left: 10px">{{ scope.row.ccount}}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="审核状态" width="180"> -->
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span v-if="scope.row.is_pass === 1" style="margin-left: 10px;color: burlywood;">审核中</span>
					<span v-if="scope.row.is_pass === 2" style="margin-left: 10px;color: #42B983;">审核通过</span>
					<span v-if="scope.row.is_pass === 3" style="margin-left: 10px;color: crimson;">审核不通过</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<!-- <el-button v-if="scope.row.deleted === 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">下架</el-button>
				<el-button v-if="scope.row.deleted !== 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">上架</el-button> -->
					<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">详情</el-button> -->
					<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
				</template>
			</el-table-column>
		</el-table>

		<!-- 添加校区对话框的相关代码 -->
		<el-dialog @opened="show()" @closed="hide()" title="新增校区" :visible.sync="dialogFormVisible" destroy-on-close>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="校区名称" prop="cName">
					<el-input v-model="ruleForm.cName"></el-input>
				</el-form-item>
				<el-form-item label="地区选择" prop="selectedOptions">
					<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChangeArea"
						@visible-change="visiblechange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="校区详细地址" prop="address">
					<div>
						<el-input v-model="ruleForm.address"></el-input>
					</div>
					<span style="color: coral;">地址填写具体到道路名称，例如：兰陵路与府佐路交汇处向西500米金陵大厦xxx机构 或 兰陵路56号xxx机构</span>
				</el-form-item>
				<el-form-item label="校区简介" prop="describes">

					<div id="demo1"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly
						v-model="ruleForm.describes"></textarea>

					<!-- <el-input v-model="ruleForm.describes"></el-input> -->
				</el-form-item>
				<el-form-item label="校区图片" prop="image">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<!-- <el-upload ref="upload" v-model="ruleForm.image" :on-success="loadSuccess"
						:before-remove="removeImagebefore" :on-remove="removeImage" :http-request="ImgUploadRequest"
						list-type="picture-card" :before-upload="Imgbeforupload" :file-list="imageArray" action=""
						:limit="6">
						<i class="el-icon-plus"></i>
					</el-upload> -->
					<el-upload v-model="ruleForm.image" accept="image/*" ref="upload" :on-change="getUploadFile"
						:on-success="loadSuccess" :auto-upload="false" :before-remove="removeImagebefore"
						:on-remove="removeImage" :before-upload="Imgbeforupload" :file-list="imageArray"
						list-type="picture-card" action="" :limit="6">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hide()">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick="3000">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 修改校区对话框的相关代码 -->
		<el-dialog @opened="showup()" @closed="hideup()" title="修改校区" :visible.sync="dialogFormVisibleupdate"
			destroy-on-close>
			<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="120px"
				class="demo-ruleForm">
				<el-form-item label="校区名称" prop="cName">
					<el-input v-model="updateRuleForm.cName"></el-input>
				</el-form-item>
				<el-form-item label="地区选择" prop="selectedOptions">
					<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChangeAreaup"
						@visible-change="visiblechange">
					</el-cascader>
				</el-form-item>
				<el-form-item label="校区地址" prop="address">
					<div>
						<el-input v-model="updateRuleForm.address"></el-input>
					</div>
					<span style="color: coral;">地址填写具体到道路名称，例如：兰陵路与府佐路交汇处向西500米金陵大厦xxx机构 或 兰陵路56号xxx机构</span>
				</el-form-item>
				<el-form-item label="校区简介" prop="describes">

					<div id="demo2"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly
						v-model="updateRuleForm.describes"></textarea>

					<!-- <el-input v-model="ruleForm.describes"></el-input> -->
				</el-form-item>
				<el-form-item label="校区图片" prop="image">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<!-- <el-upload ref="uploadup" v-model="updateRuleForm.image" :on-success="loadSuccess"
						:before-remove="removeImagebeforeup" :on-remove="removeImage" :file-list="updateImagedd"
						:http-request="ImgUploadRequestup" :before-upload="Imgbeforuploadup" list-type="picture-card"
						action="" :limit=6>
						<i class="el-icon-plus"></i>
					</el-upload> -->
					<el-upload v-model="updateRuleForm.image" accept="image/*" ref="uploadup" :on-change="getUploadFileup"
						:on-success="loadSuccess" :auto-upload="false" :before-remove="removeImagebeforeup"
						:on-remove="removeImage" :before-upload="Imgbeforuploadup" :file-list="updateImagedd"
						list-type="picture-card" action="" :limit="6">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisibleupdate">
						<!-- 在这里进已上传图片的遍历操作 -->
						<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideup()">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('updateRuleForm')" v-preventReClick="3000">修 改</el-button>
			</div>
		</el-dialog>

		<!-- 支付弹窗的相关代码 -->
		<el-dialog @opened="payshowup()" @closed="payhideup()" title="" :visible.sync="payShow" destroy-on-close>
			<!-- 更改样式 -->
			<div >
				<div class="payLogo">
					<el-image
					      style="width: 127px; height: 45px"
					      src="https://mp.xuankechong.com/img/logo-clr.96b53390.png"
					      fit="contain"></el-image>
					<span style="font-size: 35px; color: #0c35f1;">收银台</span>
				</div>
				<!-- <div>订单相关：订单编号 订单类型 价钱</div> -->
				<div class="payInfo">
					<!-- <div>{{activePlan.p_name}}</div> -->
					<div>订单类型：开户</div>
					<div>订单金额：{{activePlan.p_price}}元</div>
				</div>
				<div class="payImage">
					<div>
						<!-- https://xuankechong.oss-cn-beijing.aliyuncs.com/wap/img/WePayLogo.png -->
						<el-image
						      src="https://xuankechong.oss-cn-beijing.aliyuncs.com/wap/img/WePayLogo.png?x-oss-process=image/resize,m_lfit,h_100,m_lfit,h_250,w_250"
						      fit="scale-down"></el-image>
					</div>
					<div>
						<!-- 二维码 -->
						<vue-qr ref="Qrcode" :text="dataObj.text"></vue-qr>
					</div>

					<div>
						<el-image

						      src="https://xuankechong.oss-cn-beijing.aliyuncs.com/wap/img/desc.png"
						      fit="scale-down"></el-image>
					</div>
				</div>

			</div>

			<!-- <div slot="footer" class="dialog-footer">
				<el-button @click="payhideup()">取 消</el-button>
			</div> -->
		</el-dialog>

		<!-- 上传图片裁切弹出层 -->
		<el-dialog title="图片剪裁" :visible.sync="selfShow" append-to-body width="40%" @close="closeUpload">
		 <div class="cropper-content">
		    <div class="cropper" style="text-align:center">
		      <vueCropper
		         ref="cropper"
		         :img="selfoption.img"
		         :outputSize="selfoption.size"
		         :outputType="selfoption.outputType"
		         :info="true"
		         :full="selfoption.full"
		         :canMove="selfoption.canMove"
		         :canMoveBox="selfoption.canMoveBox"
		         :original="selfoption.original"
		         :autoCrop="selfoption.autoCrop"
		         :fixed="selfoption.fixed"
		         :fixedNumber="selfoption.fixedNumber"
		         :centerBox="selfoption.centerBox"
		         :infoTrue="selfoption.infoTrue"
		         :fixedBox="selfoption.fixedBox"
		      ></vueCropper>
		    </div>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="closeUpload">取 消</el-button>
			<el-button @click="uploadadd">+</el-button>
			<el-button @click="uploaddown">-</el-button>
		    <el-button type="primary" @click="finish">上传</el-button>
		  </div>
		</el-dialog>

		<!-- 修改图片裁切弹出层 -->
		<el-dialog title="图片剪裁" :visible.sync="selfShowup" append-to-body width="40%" @close="closeUploadup">
		 <div class="cropper-content">
		    <div class="cropper" style="text-align:center">
		      <vueCropper
		         ref="cropper"
		         :img="selfoption.img"
		         :outputSize="selfoption.size"
		         :outputType="selfoption.outputType"
		         :info="true"
		         :full="selfoption.full"
		         :canMove="selfoption.canMove"
		         :canMoveBox="selfoption.canMoveBox"
		         :original="selfoption.original"
		         :autoCrop="selfoption.autoCrop"
		         :fixed="selfoption.fixed"
		         :fixedNumber="selfoption.fixedNumber"
		         :centerBox="selfoption.centerBox"
		         :infoTrue="selfoption.infoTrue"
		         :fixedBox="selfoption.fixedBox"
		      ></vueCropper>
		    </div>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="closeUploadup">取 消</el-button>
			<el-button @click="uploadadd">+</el-button>
			<el-button @click="uploaddown">-</el-button>
		    <el-button type="primary" @click="finishup">上传</el-button>
		  </div>
		</el-dialog>

	</div>
</template>

<script>
import wangEditor from 'wangeditor'
import OSS from 'ali-oss'
import qs from 'qs'
import {
  v4 as uuidv4
} from 'uuid'

import oss from '../../utils/oss'
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode
} from 'element-china-area-data'
import {
  Function,
  evaluate
} from 'eval5'
import VueQr from 'vue-qr'

export default {
  created () {
    this.queryInfo.id = window.sessionStorage.getItem('iid') * 1
    this.getCampusList()
    // 获取平台的开户套餐
    this.getActivePlan()
    clearInterval(timerr)
  },
  mounted () {
    this.isIvip = window.sessionStorage.getItem('isIvip') * 1
  },
  data () {
    return {
      isIvip: 0,
      options: regionData,
      selectedOptions: [],
      iid: '',
      campuses: [],
      queryInfo: {
        id: 0
      },
      value: false,
      deletedParam: {
        id: 0
      },
      dialogFormVisible: false,
      dialogFormVisibleupdate: false,
      formLabelWidth: '120px',
      ruleForm: {
        cName: '',
        address: '',
        image: '',
        describes: '',
        province: '', // 省
        city: '', // 市
        county: '', // 县
        latitude: '',
        longitude: ''
      },
      updateRuleForm: {
        cName: '',
        address: '',
        image: '',
        describes: '',
        isPass: 1,
        cId: 0,
        province: '', // 省
        city: '', // 市
        county: '', // 县
        latitude: '',
        longitude: ''
      },
      updateData: {
        cName: '',
        address: '',
        image: '',
        describes: '',
        isPass: 1,
        cId: 0
      },
      updateImageTemp: [], // 修改操作时新上传的图片文件暂存区
      updateImagedd: [], // 修改操作全部文件的暂存区
      rules: {
        cName: [{
          required: true,
          message: '请输入校区名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }
        ],
        address: [{
          required: true,
          message: '请输入校区地址',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 25,
          message: '长度在 3 到 25 个字符',
          trigger: 'blur'
        }
        ],
        describes: [{
          required: false,
          message: '请填写校区简介',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请至少上传一张图片',
          trigger: 'blur'
        }]
      },
      updateRules: {
        cName: [{
          required: true,
          message: '请输入校区名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }
        ],
        address: [{
          required: true,
          message: '请输入校区地址',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 25,
          message: '长度在 3 到 25 个字符',
          trigger: 'blur'
        }
        ],
        describes: [{
          required: false,
          message: '请填写校区简介',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请至少上传一张图片',
          trigger: 'blur'
        }]
      },
      editor: null,
      dialogImageUrl: '', // 图片地址
      dialogVisible: false, // 是否允许图片
      dialogVisibleupdate: false,
      imageArray: [],
      updateImageArray: [],
      activePlan: {},
      payInfo: {
        g_name: '',
        g_price: '',
        i_id: '',
        p_id: ''
      },
      dataObj: {
        text: ''
      },
      payShow: false,
      getOrder: {
        o_number: ''
      },
      selfShow: false,
      selfShowup: false,
      selfoption: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      fileinfo: {}
    }
  },
  components: {
    VueQr
  },

  methods: {
    uploadadd () {
      this.$refs.cropper.changeScale(1)
    },
    uploaddown () {
      this.$refs.cropper.changeScale(-1)
    },
    closeUpload () {
      console.log('关闭上传组件')
      this.selfShow = false
      console.log('看看数组的结构哦', this.imageArray)
      // console.log('图片的地址',this.dialogImageUrl)
      // // console.log(this.imageArray)
      if (this.imageArray.length > 0) {
        this.imageArray = this.imageArray.filter(function (v) {
          return v.url.indexOf('https://xuankechong') === 0
        })
        // console.log('筛选后的数组',this.imageArray)
        // 这一步有问题
        var aa = this.imageArray.map(function (v) {
          return v.url
        })
        this.ruleForm.image = aa.join(',')
      } else {
        this.imageArray = []
        this.ruleForm.image = ''
      }
    },
    closeUploadup () {
      console.log('关闭上传组件')
      this.selfShowup = false
      // this.updateImagedd.pop()
      if (this.updateImagedd.length > 0) {
        this.updateImagedd = this.updateImagedd.filter(function (v) {
          return v.url.indexOf('https://xuankechong') === 0
        })
        // console.log('筛选后的数组',this.imageArray)
        // 这里有点问题
        var aa = this.updateImagedd.map(function (v) {
          return v.url
        })
        this.updateRuleForm.image = aa.join(',')
      } else {
        this.updateImagedd = []
        this.updateRuleForm.image = ''
      }
    },
    finish () {
      this.$refs.cropper.getCropBlob((data) => { // 获取当前裁剪好的数据
        const filee = new File([data], this.fileinfo.name, { type: this.fileinfo.type, lastModified: Date.now() })
        console.log('文件的信息', filee)
        oss.ossUploadFilee(filee).then(res => {
          // console.log(res.res.requestUrls)
          // var url = ''
          // if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
          // 	url = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
          // } else {
          // 	url = res.res.requestUrls[0]
          // }
          // // this.imageArray.push(res.res.requestUrls[0])
          // this.imageArray.push(url)
          // // console.log(this.imageArray)
          // this.ruleForm.image = this.imageArray.join(',')

          var url = ''
          if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
            url = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
          } else {
            url = res.res.requestUrls[0]
          }
          console.log('qqqq', url)
          const obj = {
            // url: res.res.requestUrls[0]
            url: url
          }
          // 向image中添加图片
          // let obj = {
          // 	url: res.res.requestUrls[0]
          // }
          this.imageArray.push(obj)
          const aa = this.imageArray.map(v => v.url)
          this.ruleForm.image = aa.join(',')
          this.selfShow = false
        })
        // 调用接口上传
        // upLoadFile(formData).then(result => {
        // 	console.log(result)
        // 	this.selfShow = false;
        // })
			 })
    },
    finishup () {
      this.$refs.cropper.getCropBlob((data) => { // 获取当前裁剪好的数据
        const filee = new File([data], this.fileinfo.name, { type: this.fileinfo.type, lastModified: Date.now() })
        console.log('文件的信息', filee)
        oss.ossUploadFilee(filee).then(res => {
          // console.log(res.res.requestUrls)
          var urll = ''
          if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
            urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
          } else {
            urll = res.res.requestUrls[0]
          }
          console.log('qqqq', urll)
          const obj = {
            // url: res.res.requestUrls[0]
            url: urll
          }
          // 向image中添加图片
          // let obj = {
          // 	url: res.res.requestUrls[0]
          // }
          this.updateImagedd.push(obj)
          const aa = this.updateImagedd.map(v => v.url)
          this.updateRuleForm.image = aa.join(',')
          console.log('当前的照片', this.updateRuleForm.image)
          this.selfShowup = false
        })
        // 调用接口上传
        // upLoadFile(formData).then(result => {
        // 	console.log(result)
        // 	this.selfShow = false;
        // })
			 })
    },
    getUploadFile (file, fileList) {
      var files = file.raw
      this.fileinfo = file // 保存下当前文件的一些基本信息
      const reader = new FileReader() // 创建文件读取对象
      reader.onload = async e => {
        let data
			 	if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.selfoption.img = data // 设置option的初始image
        this.selfShow = true
      }
      reader.readAsArrayBuffer(files)
      this.selfoption.fixedNumber = [4, 3] // 图片的裁剪宽高比在这里也可以进行设置
    },

    getUploadFileup (file, fileList) {
      var files = file.raw
      this.fileinfo = file // 保存下当前文件的一些基本信息
      const reader = new FileReader() // 创建文件读取对象
      reader.onload = async e => {
        let data
			 	if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.selfoption.img = data // 设置option的初始image
        this.selfShowup = true
      }
      reader.readAsArrayBuffer(files)
      this.selfoption.fixedNumber = [4, 3] // 图片的裁剪宽高比在这里也可以进行设置
    },
    payhideup () {
      this.payShow = false
    },
    payshowup () {
      this.payShow = true
    },
    // 获取开户套餐
    getActivePlan () {
      this.$http({
        url: 'company/campus/getActivePlan',
        method: 'GET',
        params: {

        }
      }).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          this.activePlan = res.data.data[0]

          // console.log("开户套餐：------>",this.activePlan)
        } else {
          this.$message.error('获取开户套餐失败')
        }
      })
    },
    visiblechange () {
      // console.log("变化了")
      document.getElementsByClassName('el-cascader-panel')[0].style.height = '200px'
    },
    handleChangeAreaup (value) {
      if (value[1] != null && value[2] != null) {
        // 修改该
        // var dz = CodeToText[value[0]] + '/' + CodeToText[value[1]] + '/' + CodeToText[value[2]]
        this.updateRuleForm.province = value[0]
        this.updateRuleForm.city = value[1]
        this.updateRuleForm.county = value[2]

        // this.addressid = value[2]
      } else {
        if (value[1] != null) {
          dz = CodeToText[value[0]] + '/' + CodeToText[value[1]]
          // this.addressid = value[1]
        } else {
          dz = CodeToText[value[0]]
          // this.addressid = value[0]
        }
      }
      // this.address = dz
      console.log(this.updateRuleForm.province)
      console.log(this.updateRuleForm.city)
      console.log(this.updateRuleForm.county)
      // console.log(dz)
      this.selectedOptions = value
      console.log(value)
    },
    handleChangeArea (value) {
      if (value[1] != null && value[2] != null) {
        // 修改该
        // var dz = CodeToText[value[0]] + '/' + CodeToText[value[1]] + '/' + CodeToText[value[2]]
        this.ruleForm.province = value[0]
        this.ruleForm.city = value[1]
        this.ruleForm.county = value[2]

        // this.addressid = value[2]
      } else {
        if (value[1] != null) {
          dz = CodeToText[value[0]] + '/' + CodeToText[value[1]]
          // this.addressid = value[1]
        } else {
          dz = CodeToText[value[0]]
          // this.addressid = value[0]
        }
      }
      // this.address = dz
      // console.log(this.ruleForm.province)
      // console.log(this.ruleForm.city)
      // console.log(this.ruleForm.county)
      // console.log(dz)
      this.selectedOptions = value
      console.log(value)
    },
    addCampuse () {
      // 判断是不是vip，不是vip就不能创建校区
      console.log('是会员？', this.isIvip)
      if (this.isIvip === 2) {
        this.dialogFormVisible = true
      } else {
        this.$message.error('您还不未开通该功能，暂时不能创建校区')
        // 生成网页支付的二维码
        // this.getActivePlan()
        // 构建初始化参数
        console.log('参数----》', this.activePlan)
        this.payInfo.g_name = this.activePlan.p_name
        this.payInfo.g_price = parseInt(evaluate(this.activePlan.p_price * 100))
        this.payInfo.i_id = window.sessionStorage.getItem('iid') * 1
        this.payInfo.p_id = this.activePlan.p_id
        // 开始生成二维吗
        this.$http({
          url: 'v3/nativePay',
          // params: qs.stringify(this.payInfo)
          params: this.payInfo
        }).then(res => {
          console.log('支付的开始', res)
          this.dataObj.text = res.data.code_url
          // 构建订单查询参数
          this.getOrder.o_number = res.data.o_number
          this.payShow = true
          // 首先清除定时器
          clearInterval(timer)

          // 开始进行定时器的操作
          const timer = setInterval(() => {
            setTimeout(() => {
              this.$http({
                url: 'company/sys-user/getOrderByOnum',
                // params: qs.stringify(this.payInfo)
                params: this.getOrder
              }).then(res => {
                console.log('订单创建的状态', res)
                if (res.data.code === 200) {
                  // 如需要停止定时器，只需加入以下：
                  clearInterval(timer)
                  // 更改用户的缓存状态
                  window.sessionStorage.setItem('isIvip', 2)
                  this.isIvip = 2
                  // 关闭弹窗  给提示
                  this.payShow = false
                  this.$message.success('开户成功')
                }
              })
            }, 0)
          }, 5000)
        })
      }
    },
    timer () {

    },
    async getCampusList () {
      const {
        data
      } = await this.$http.get('company/campus/allCampus', {
        params: this.queryInfo
      })
      console.log(data)
      if (data.code !== 200) {
        this.$message.error(data.message)
      }
      this.campuses = data.data
    },
    // 点击修改按钮时的方法
    handleEdit (index, row) {
      console.log(index, row)
      // 在这里进行数据的赋值操作
      this.updateRuleForm.cName = row.c_name
      this.updateRuleForm.address = row.address
      // 在这里进行数据的判断操作
      // 1重新打开页面的时候，就赋值updateImagedd里面的数值
      // 2第一次打开页面就赋值row里面的数值
      // if(row.image.length !== this.updateRuleForm.image.length){
      // 	// 第一次打开
      // 	this.updateRuleForm.image =this.updateRuleForm.image
      // }else{
      // 	this.updateRuleForm.image = row.image
      // }
      this.updateRuleForm.image = row.image
      this.updateRuleForm.describes = row.describes
      this.updateRuleForm.cId = row.c_id
      this.updateRuleForm.is_vip = row.is_vip
      this.updateRuleForm.i_id = row.i_id
      this.updateRuleForm.province = row.province
      this.updateRuleForm.city = row.city
      this.updateRuleForm.county = row.county
      // console.log(typeof(row.city))
      // var aa = TextToCode[city].code
      // console.log(aa)
      // console.log(TextToCode[row.city].code)

      // console.log(row.city)

      // console.log(row.county)
      var addr = row.province + ',' + row.city + ',' + row.county
      this.selectedOptions = addr.split(',')
      console.log('addr', this.selectedOptions)
      // this.selectedOptions =
      // this.updateImagedd = []
      // console.log(this.updateImagedd)
      // 进行图片列表的封装操作
      const imgArr = this.updateRuleForm.image.split(',')
      imgArr.forEach(item => {
        const obj = {
          url: item
        }
        this.updateImagedd.push(obj)
      })
      // 打开弹出框
      this.dialogFormVisibleupdate = true
    },
    handleDelete (index, row) {
      console.log(index, row)
    },
    async deletedChange (campusesInfo) {
      // console.log(campusesInfo)
      // false的时候，deleted为0    true的时候deleted为1
      // 进行商品的上下架操作
      this.deletedParam.id = campusesInfo.c_id
      console.log(this.deletedParam.id)
      if (campusesInfo.deleted === 0) {
        // 下架
        const {
          data
        } = await this.$http.post('company/campus/deleteCampus', qs.stringify(this.deletedParam))
        // console.log(data)
        if (data.code !== 200) {
          return this.$message.error('操作失败，校区未成功下架')
        }
        campusesInfo.deleted = 1
        return this.$message.success('操作成功，校区已成功下架')
        // 这个时候修改元数组
      } else {
        // 上架
        const {
          data
        } = await this.$http.post('company/campus/undeleteCampus', qs.stringify(this.deletedParam))
        // console.log(data)
        if (data.code !== 200) {
          return this.$message.error('操作失败，校区未成功上架')
        }
        campusesInfo.deleted = 0
        return this.$message.success('操作成功，校区已成功上架')
        // 这个时候修改元数组
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.selectedOptions.length < 3) {
            this.$message.error('提交失败，请选择区域！')
            return false
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          // 先调用地址解析拿到经纬度
          var addr = CodeToText[this.ruleForm.province] + CodeToText[this.ruleForm.city] +
							CodeToText[this.ruleForm.county] + this.ruleForm.address
          console.log(addr)
          this.$http({
            url: 'getAddr',
            method: 'GET',
            params: {
              addr: addr
            }
          }).then(res => {
            if (res.data.code === 200) {
              this.ruleForm.latitude = res.data.data.result.location.lat
              this.ruleForm.longitude = res.data.data.result.location.lng
              console.log('位置信息', this.ruleForm.latitude, this.ruleForm.longitude)
              this.$http({
                url: 'company/campus/addCampus',
                method: 'POST',
                header: {
                  'Content-Type': 'application/json;charset=utf-8' // 如果写成contentType会报错
                },
                // data: qs.stringify(this.ruleForm)
                data: this.ruleForm
              }).then(res => {
                console.log(res.data)
                // 开始进行校区的判断操作
                if (res.data.code === 200) {
                  this.$message.success('创建成功，审核通过后自动上架')
                } else {
                  this.$message.error(res.data.message)
                }
                // 进行遮罩层的关闭操作
                loading.close()
                // 进行弹出框的关闭操作
                this.dialogFormVisible = false
                this.ruleForm.address = ''
                this.ruleForm.cName = ''
                this.ruleForm.image = ''
                this.ruleForm.describes = ''
                this.selectedOptions = []
                this.getCampusList()
              })
            } else {
              this.$message.error('获取经纬度失败，请稍后重新提交！')
              loading.close()
            }
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    submitUpdateForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.selectedOptions.length < 3) {
            this.$message.error('提交失败，请选择区域！')
            return false
          }
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          // 先调用地址解析拿到经纬度
          var addr = CodeToText[this.updateRuleForm.province] + CodeToText[this.updateRuleForm
            .city] +
							CodeToText[this.updateRuleForm.county] + this.updateRuleForm.address
          // console.log(addr)
          this.$http({
            url: 'getAddr',
            method: 'GET',
            params: {
              addr: addr
            }
          }).then(res => {
            if (res.data.code === 200) {
              this.updateRuleForm.latitude = res.data.data.result.location.lat
              this.updateRuleForm.longitude = res.data.data.result.location.lng
              // console.log("位置信息",this.updateRuleForm.latitude,this.updateRuleForm.longitude)
              this.$http({
                url: 'company/campus/updateCampus',
                method: 'POST',
                header: {
                  'Content-Type': 'application/json;charset=utf-8' // 如果写成contentType会报错
                },
                data: this.updateRuleForm
              }).then(res => {
                // console.log(res.data)
                // 开始进行校区更改结果的判断操作
                if (res.data.code === 200) {
                  this.$message.success('修改成功，审核通过后自动上架')
                  // 更新缓存数据
                  if (this.updateRuleForm.is_pass === 2) {
                    var campusNum = window.sessionStorage.getItem(
                      'campusNum') * 1
                    campusNum = campusNum - 1
                    window.sessionStorage.setItem('campusNum', campusNum)
                  }
                } else {
                  this.$message.error(res.data.message)
                }
                // 进行遮罩层的关闭操作
                loading.close()
                // 进行弹出框的关闭操作
                this.updateRuleForm.address = ''
                this.updateRuleForm.cName = ''
                this.updateRuleForm.image = ''
                this.updateRuleForm.describes = ''
                this.updateImagedd = []
                this.updateRuleForm.province = ''
                this.updateRuleForm.city = ''
                this.updateRuleForm.county = ''
                this.selectedOptions = []
                this.dialogFormVisibleupdate = false
                this.getCampusList()
              })
            } else {
              this.$message.error('获取经纬度失败，请稍后重新提交！')
              loading.close()
            }
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    beforeDestroy () {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    },
    getEditorData () {
      // 通过代码获取编辑器内容
      const data = this.editor.txt.html()
      // alert(data)
    },
    // 显示添加校区的富文本
    show () {
      const editor = new wangEditor('#demo1')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.ruleForm.describes = newHtml
      }

      const client = oss.createOssClient1()
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        client.put(uuidv4(), resultFiles[0])
          .then(function (res) {
            console.log(res)
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url)
          }).catch(function (err) {
            console.log(err)
          })
        // oss.ossUploadFile(uuidv4(), resultFiles[0])
        // 	.then(function(res) {
        // 		console.log(res)
        // 		// 上传图片，返回结果，将图片插入到编辑器中
        // 		insertImgFn(res.url)
        // 	}).catch(function(err) {
        // 		console.log(err)
        // 	})
      }

      // 创建编辑器
      editor.create()
      this.editor = editor
    },
    showup () {
      const editor = new wangEditor('#demo2')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.updateRuleForm.describes = newHtml
      }

      const client = oss.createOssClient1()
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        client.put(uuidv4(), resultFiles[0])
          .then(function (res) {
            console.log(res)
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url)
          }).catch(function (err) {
            console.log(err)
          })
      }

      // 创建编辑器
      editor.create()
      editor.txt.html(this.updateRuleForm.describes)
      this.editor = editor
    },
    // 隐藏富文本
    hide () {
      // this.$message.success("asdsadsa")
      this.dialogFormVisible = false
      this.ruleForm.address = ''
      this.ruleForm.cName = ''
      this.ruleForm.image = ''
      this.ruleForm.describes = ''
      this.imageArray = []
    },
    hideup () {
      // this.$message.success("asdsadsa")
      this.updateRuleForm.address = ''
      this.updateRuleForm.cName = ''
      this.updateRuleForm.image = ''
      this.updateRuleForm.describes = ''
      this.updateImagedd = []
      this.dialogFormVisibleupdate = false
    },
    Imgbeforupload (file) {
      var that = this
      // console.log("jinfangfa")
      // 进行图片类型和大小的限制
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jpg'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'JPG'
      const extension5 = testmsg === 'JPEG'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 3
      // const isLt2M = file.size / 1024  <= 500
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5) {
        that.$message.error({
          message: '图片的格式只能是 png、jpg、jpeg格式!',
          duration: 6000
        })
        // that.$refs.upload.clearFiles()
        that.$refs.upload.uploadFiles.pop()
        return false // 必须加上return false; 才能阻止
      }
      if (!isLt2M) {
        // that.$message.error('图片大小不能超过10M!请重新选择!')
        that.$message.error({
          message: '图片大小不能超过3M!请重新选择!',
          duration: 6000
        })
        // that.$refs.upload.clearFiles()
        that.$refs.upload.uploadFiles.pop()
        return false // 必须加上return false; 才能阻止
      }
      const isSize = new Promise(function (resolve, reject) {
        const width = 200
        const height = 200
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          const valid = img.width / img.height == (4 / 3)
          // console.log('valid',valid)
          valid ? resolve() : reject()
          if (!valid) {
            // that.$refs.upload.clearFiles()
            console.log(that.$refs.upload.uploadFiles)
            // console.log(that.imageArray)
            // that.imageArray.pop()
            // return valid
          }
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        // that.$message.error('图片只能上传4:3尺寸的图片，请重新选择!')
        that.$message.error({
          message: '图片只能上传4:3尺寸的图片，请重新选择!',
          duration: 6000
        })
        that.$refs.upload.uploadFiles.pop()
        return Promise.reject()
        return false // 必须加上return false; 才能阻止
      })
      return extension || extension2 || extension3 || extension4 || extension5 && isLt2M && isSize
    },
    Imgbeforuploadup (file) {
      var that = this
      // console.log("jinfangfa")
      // 进行图片类型和大小的限制
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jpg'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'JPG'
      const extension5 = testmsg === 'JPEG'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 3
      // const isLt2M = file.size / 1024  <= 500
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5) {
        // that.$message.error('图片的格式只能是 png、jpg、jpeg格式!')
        that.$message.error({
          message: '图片的格式只能是 png、jpg、jpeg格式!',
          duration: 6000
        })
        // that.$refs.upload.clearFiles()
        that.$refs.uploadup.uploadFiles.pop()
        return false // 必须加上return false; 才能阻止
      }
      if (!isLt2M) {
        // that.$message.error('图片大小不能超过10M!请重新选择!')
        that.$message.error({
          message: '图片大小不能超过3M!请重新选择!',
          duration: 6000
        })
        // that.$refs.upload.clearFiles()
        that.$refs.uploadup.uploadFiles.pop()
        return false // 必须加上return false; 才能阻止
      }
      const isSize = new Promise(function (resolve, reject) {
        const width = 200
        const height = 200
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          const valid = img.width / img.height == (4 / 3)
          // console.log('valid',valid)
          valid ? resolve() : reject()
          if (!valid) {
            // that.$refs.upload.clearFiles()
            // console.log(that.$refs.uploadup.uploadFiles)
            // console.log(that.imageArray)
            // that.imageArray.pop()
            // return valid
          }
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        // that.$message.error('图片只能上传4:3尺寸的图片，请重新选择!')
        that.$message.error({
          message: '图片只能上传4:3尺寸的图片，请重新选择!',
          duration: 6000
        })
        that.$refs.uploadup.uploadFiles.pop()
        return Promise.reject()
        return false // 必须加上return false; 才能阻止
      })
      return extension || extension2 || extension3 || extension4 || extension5 && isLt2M && isSize
    },
    async ImgUploadRequest (option) {
      // console.log("进入上传方法")
      var that = this
      oss.ossUploadFile(option).then(res => {
        // console.log(res.res.requestUrls)
        var url = ''
        if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
          url = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
        } else {
          url = res.res.requestUrls[0]
        }
        // this.imageArray.push(res.res.requestUrls[0])
        that.imageArray.push(url)
        // console.log(this.imageArray)
        that.ruleForm.image = that.imageArray.join(',')
      })
    },
    async ImgUploadRequestup (option) {
      // console.log("进入上传方法")
      oss.ossUploadFile(option).then(res => {
        var urll = ''
        if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
          urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
        } else {
          urll = res.res.requestUrls[0]
        }
        console.log('qqqq', urll)
        const obj = {
          // url: res.res.requestUrls[0]
          url: urll
        }
        // 向image中添加图片
        // let obj = {
        // 	url: res.res.requestUrls[0]
        // }
        this.updateImagedd.push(obj)
        const aa = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.image = aa.join(',')
        // console.log('##', this.updateRuleForm.image)
      })
    },
    removeImage (file, fileList) {},
    // 删除之前，对图片数据进行维护(添加校区)
    removeImagebefore (file, fileList) {
      // 1调用图片删除的api
      var isDeleted = oss.ossDeleteImages(file)
      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        // console.log(this.imageArray)
        this.imageArray.splice(this.imageArray.findIndex(item => item === file.response.res.requestUrls[0], 1))
        // console.log(this.imageArray)
        if (this.imageArray.length !== 0) {
          this.ruleForm.image = this.imageArray.join(',')
        } else {
          this.ruleForm.image = ''
        }
        this.$message.success('删除成功')
      } else {
        this.$message.success('删除失败')
      }
      return isDeleted
    },
    //  删除之前，对图片数据进行维护(修改校区)
    // 注意：修改校区的时候最后一张图片不能进行删除
    removeImagebeforeup (file, fileList) {
      // console.log(file.response.res.requestUrls[0])
      if (this.updateImagedd.length < 1) {
        this.$message.error('请至少保留一张图片')
        return false
      }
      // 获取删除文件的下标
      let img = this.updateImagedd.map(v => v.url)
      var Index = img.findIndex(item => item === file.url)
      // 1调用图片删除的api
      // console.log('##',fileList)
      var fileName = file.url.substr(file.url.indexOf('/image'))
      // console.log('$$',fileName)
      var isDeleted = oss.ossDeleteImage(fileName)

      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        this.updateImagedd.splice(Index, 1)
        img = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.image = img.join()
        // 进行数据库的删除操作
        this.deleteImage()
        // this.getCampusList()
        return true
      } else {
        this.$message.success('删除失败')
        return false
      }
    },
    loadSuccess (file, fileList) {
      // console.log("上传成功！", file, fileList)
    },
    deleteImage () {
      this.$http({
        url: 'company/campus/updateImagesById',
        method: 'POST',
        // header: {
        // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
        // },
        data: qs.stringify({
          id: this.updateRuleForm.cId,
          img: this.updateRuleForm.image
        })
      }).then(res => {
        if (res.data.code === 200) {
          this.$message.success('删除成功')
        }
        return res.data
      })
    }

  },
  // 格式化日期
  filters: {
    formatTimer: function (value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m
    }
  }

}
</script>

<style scoped>
	.addButton {
		margin-bottom: 20px;
	}

	.home {
		width: 100%;
		margin-right: 20px;
		position: relative;
	}

	/* .el-cascader-menu {
		height: 300px;
	} */

	.el-cascader-panel {
		height: 400px;
	}

	.el-scrollbar-panel {
		overflow-x: hidden;
	}
	.payLogo{
		display: flex;
		align-items: center;
	}
	.payInfo{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-left: 30px;
		padding-right: 30px;
		background-color: #f2f2f2;
		height: 80px;
		font-size: 20px;
		margin: 20px 0;
	}

	.payImage{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	/* 设置裁剪框样式 */
	.cropper-content {

	}
	.cropper {
	  width: auto;
	  height: 600px;
	}
</style>
