<template>
	<el-container class="home-container">
		<el-header>
			<div>
				<span>选课虫商家版后台</span>
			</div>
			<div>
				<el-badge :value="unMessageNum" :max="99"  class="item">
				  <el-button type="info" @click="meaasgeOpen" size="small">消息</el-button>
				</el-badge>
				<el-button type="info" @click="logout">退出</el-button>
			</div>

		</el-header>
		<el-container>
			<el-aside :width="isCollapse ? '64px' : '200px'">
				<div class="toggle-button" @click="toggleCollpase">|||</div>
				<el-menu background-color="#313743" text-color="#fff" active-text-color="#ffd04b" unique-opened :collapse="isCollapse"
          :collapse-transition="false" :router="true" :default-active="activePath" >
					<el-submenu index="1">
						<template slot="title">
							<i class="el-icon-s-home"></i>
							<span>校区管理</span>
						</template>
						<el-menu-item index="campus" @click="activeCollapse('campus')" >
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>校区列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="2">
						<template slot="title">
							<i class="el-icon-s-claim"></i>
							<span>课程管理</span>
						</template>
						<el-menu-item index="course" @click="activeCollapse('course')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>课程列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<!-- <el-menu-item index="2-2">
							<template slot="title">

								<i class="el-icon-menu"></i>

								<span>新增课程</span>

							</template>
						</el-menu-item> -->
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">
							<i class="el-icon-phone-outline"></i>
							<span>预约管理</span>
						</template>
						<el-menu-item index="oldsubscribe" @click="activeCollapse('oldsubscribe')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>历史预约</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<el-menu-item index="newsubscribe" @click="activeCollapse('newsubscribe')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>新增预约</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="4">
						<template slot="title">
							<i class="el-icon-s-order"></i>
							<span>充值管理</span>
						</template>
						<el-menu-item index="order" @click="activeCollapse('order')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>充值记录</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
					<el-submenu index="5">
						<template slot="title">
							<i class="el-icon-setting"></i>
							<span>机构管理</span>
						</template>
						<el-menu-item index="institution" @click="activeCollapse('institution')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>修改信息</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
						<!-- <el-menu-item index="password" @click="activeCollapse('password')">
							<template slot="title">

								<i class="el-icon-menu"></i>

								<span>修改密码</span>

							</template>
						</el-menu-item> -->
					</el-submenu>
					<el-submenu index="6">
						<template slot="title">
							<i class="el-icon-medal-1"></i>
							<span>员工管理</span>
						</template>
						<el-menu-item index="worker" @click="activeCollapse('worker')">
							<template slot="title">
								<!-- 二级菜单的模板区域 -->
								<i class="el-icon-menu"></i>
								<!-- 图标 -->
								<span>员工列表</span>
								<!-- 文本 -->
							</template>
						</el-menu-item>
					</el-submenu>
				</el-menu>
			</el-aside>
			<el-main>
				<router-view></router-view>
			</el-main>

			<el-drawer
			  title="未读消息"
			  :visible.sync="drawer"
			  size="50%">
			  <div>
				  <!-- 进行数据的展示操作 -->
				 <!-- <el-card class="box-card">
				  <div v-for="(value,key) in unMessageList" :key="key" class="text item">
				     {{value.m_id }}
				   </div>
				 </el-card> -->
				  <el-table
				     :data="unMessageList"
				     style="width: 100%">
				     <el-table-column>
				       <template slot-scope="scope">
				         <i class="el-icon-time"></i>
				         <span style="margin-left: 10px">{{ scope.row.create_time | formatTimer }}</span>
				       </template>
				     </el-table-column>
				     <el-table-column>
				       <template slot-scope="scope">

				           <div slot="reference" class="name-wrapper">
				             <span size="medium">{{ scope.row.m_title }}</span>
				           </div>

				       </template>
				     </el-table-column>
				     <el-table-column >
				       <template slot-scope="scope">
				         <el-button
				           size="mini"
				           @click="handleEdit(scope.$index, scope.row)">详情</el-button>
				         <!-- <el-button
				           size="mini"
				           type="danger"
				           @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
				       </template>
				     </el-table-column>
				   </el-table>
			   <!-- <el-button @click="innerDrawer = true">详情</el-button> -->
			   <el-drawer
			     title="消息详情"
			     :append-to-body="true"
				 @close="closeDrawer(detail.m_id)"
			     :visible.sync="innerDrawer">
			     <!-- <p>_(:зゝ∠)_</p> -->
				 <el-descriptions  :column="1" >

				     <el-descriptions-item label="消息标题">{{detail.m_title}}</el-descriptions-item>
				     <el-descriptions-item label="创建时间">{{detail.create_time | formatTimer}}</el-descriptions-item>
				     <el-descriptions-item label="消息内容"><div v-html="detail.m_msg"></div></el-descriptions-item>
				     <!-- <el-descriptions-item label="备注">
				       <span >学校</span>
				     </el-descriptions-item>
				     <el-descriptions-item label="联系地址">江苏省苏州市吴中区吴中大道 1188 号</el-descriptions-item> -->
				   </el-descriptions>
			   </el-drawer>
			  </div>
			</el-drawer>

		</el-container>
	</el-container>
</template>

<script>
export default {
  created () {
    this.activePath = window.sessionStorage.getItem('activePath')
    this.unMessageNum = window.sessionStorage.getItem('unMessageNum') * 1
    this.u_id = window.sessionStorage.getItem('sap')
    this.allMessage()
  },
  data () {
    return {
      isCollapse: false,
      activePath: '',
      unMessageNum: 0,
      unMessageList: [],
      drawer: false,
      innerDrawer: false,
      u_id: '',
      detail: {}
    }
  },
  methods: {
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    toggleCollpase () {
      this.isCollapse = !this.isCollapse
    },
    activeCollapse (activePath) {
      this.activePath = ''
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    meaasgeOpen () {
      // console.log('点击了消息按钮！')
      this.drawer = true
    },
    async allMessage () {
      const {
        data
      } = await this.$http.get('company/massage/AllMessage', {
        params: { u_id: this.u_id }
      })
      console.log(data)
      if (data.code !== 200) {
        this.$message.error(data.message)
      }
      this.unMessageList = data.data
    },
    handleEdit (index, row) {
      console.log('点击了', index, row)
      this.detail = row
      this.innerDrawer = true
    },
    closeDrawer (val) {
      console.log('关闭了', val)
      this.$http({
        url: 'company/massage/updateMessage',
        method: 'POST',
        params: { m_id: val }
      }).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          // 修改当前列表页的数组
          this.unMessageList.splice(this.unMessageList.findIndex(item => item.m_id === val), 1)
          // 更改缓存中的列表数据
          this.unMessageNum = this.unMessageNum - 1
          window.sessionStorage.setItem('unMessageNum', this.unMessageNum)
          // console.log(this.unMessageList)
        }
      })
    }
  },
  // 格式化日期
  filters: {
    formatTimer: function (value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m
    }
  }
}
</script>

<style scoped lang="less">
	.home-container {
		height: 100%;
	}

	.el-aside {
		background-color: #313743;
		.el-menu{
			border-right: none;
		}
	}

	.el-header {
		background-color: #363d3f;
		display: flex;
		justify-content: space-between;
		color: white;
		font-size: 24px;
		align-items: center;
	}

	.el-main {
		background-color: #e9edf0;
	}

	.toggle-button {
	  background: #4a5064;
	  font-size: 10px;
	  text-align: center;
	  line-height: 24px;
	  color: #fff;
	  letter-spacing: 0.2em;
	  cursor: pointer;
	}
	.el-badge{
		margin: auto 30px;
	}
</style>
