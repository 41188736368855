import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login'
import Home from '../views/Home'
import Welcome from '../views/Welcome'

import Campus from '../views/campus/Campus'
import Course from '../views/course/course'
import Institution from '../views/institution/Institution'
import OldSubscribe from '../views/subscribe/oldsubscribe'
import NewSubscribe from '../views/subscribe/newsubscribe'
import Order from '../views/order/order'
import Password from '../views/institution/password'
import GetPassword from '../views/password'
import Update from '../views/update'
import Worker from '../views/worker/worker'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login'
},
{
  path: '/login',
  component: Login
},
{
  path: '/getpassword',
  component: GetPassword
},
{
  path: '/update',
  component: Update
},
{
  path: '/home',
  component: Home,
  redirect: '/welcome',
  children: [{
    path: '/welcome',
    component: Welcome
  },
  {
    path: '/campus',
    component: Campus
  },
  {
    path: '/course',
    component: Course
  }, {
    path: '/institution',
    component: Institution
  }, {
    path: '/oldsubscribe',
    component: OldSubscribe
  }, {
    path: '/newsubscribe',
    component: NewSubscribe
  },
  {
    path: '/order',
    component: Order
  },
  {
    path: '/password',
    component: Password
  },
  {
    path: '/worker',
    component: Worker
  }
  ]
}

]

const router = new VueRouter({
  routes
})

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === '/login') return next()
  if (to.path === '/getpassword') return next()
  const token = window.sessionStorage.getItem('satoken')
  if (!token) return next('/login')
  next()
})

export default router
