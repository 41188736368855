<template>
	<div>
		<h1>Welcome</h1>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
