import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import axios from 'axios'
import qs from 'qs'
import preventReClick from './utils/preventReClick.js'
import VueCropper from 'vue-cropper'
import '@/utils/detectZoom';
Vue.use(VueCropper)
Vue.use(preventReClick)
axios.defaults.baseURL = 'https://www.xuankechong.com/'
// axios.defaults.baseURL = 'https://101.201.65.99:8081/'
// axios.defaults.baseURL = 'http://localhost:8081/'
// axios.defaults.withCredentials = true
axios.interceptors.request.use(config => {
  config.headers.satoken = window.sessionStorage.getItem('satoken')
  return config
})

Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  qs,
  render: h => h(App)
}).$mount('#app')
