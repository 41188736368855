<template>
	<div>
		<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="100px"
			class="demo-ruleForm">
			<el-form-item label="机构名称" prop="i_name">
				<el-input v-model="updateRuleForm.i_name"></el-input>
			</el-form-item>
			<!-- <el-form-item label="校区地址" prop="address">
				<el-input v-model="updateRuleForm.address"></el-input>
			</el-form-item> -->

			<el-form-item label="机构简介" prop="i_describes">

				<div id="demo"></div>
				<textarea name="" id="" cols="170" hidden="hidden" rows="20" readonly
					v-model="updateRuleForm.i_describes"></textarea>
			</el-form-item>

			<el-form-item label="机构图片" prop="i_img">
				<!-- <el-input v-model="ruleForm.image"></el-input> -->
				<!-- <el-upload v-model="updateRuleForm.i_img" :on-success="loadSuccess"
					:before-remove="removeImagebeforeup" :on-remove="removeImage" :file-list="updateImagedd"
					:http-request="ImgUploadRequestup" list-type="picture-card" action="" :limit=6>
					<i class="el-icon-plus"></i>
				</el-upload> -->

				<el-upload v-model="updateRuleForm.i_img" accept="image/*" ref="uploadup" :on-change="getUploadFileup"
					:on-success="loadSuccess" :auto-upload="false" :before-remove="removeImagebeforeup"
					:on-remove="removeImage" :before-upload="Imgbeforuploadup" :file-list="updateImagedd"
					list-type="picture-card" action="" :limit="6">
					<i class="el-icon-plus"></i>
				</el-upload>
				<el-dialog :visible.sync="dialogVisibleupdate">
					<!-- 在这里进已上传图片的遍历操作 -->
					<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
			</el-form-item>

		</el-form>
		<div slot="footer" class="dialog-footer">
			<!-- <el-button @click="hideup()">取 消</el-button> -->
			<el-button type="primary" @click="submitUpdateForm('updateRuleForm')" v-preventReClick="3000">修 改</el-button>
		</div>

		<!-- 修改图片裁切弹出层 -->
		<el-dialog title="图片剪裁" :visible.sync="selfShowup" append-to-body width="40%" @close="closeUploadup" custom-class="jg-file-viewer">
		 <div class="cropper-content">
		    <div class="cropper" style="text-align:center">
		      <vueCropper
		         ref="cropper"
		         :img="selfoption.img"
		         :outputSize="selfoption.size"
		         :outputType="selfoption.outputType"
		         :info="true"
		         :full="selfoption.full"
		         :canMove="selfoption.canMove"
		         :canMoveBox="selfoption.canMoveBox"
		         :original="selfoption.original"
		         :autoCrop="selfoption.autoCrop"
		         :fixed="selfoption.fixed"
		         :fixedNumber="selfoption.fixedNumber"
		         :centerBox="selfoption.centerBox"
		         :infoTrue="selfoption.infoTrue"
		         :fixedBox="selfoption.fixedBox"
		      ></vueCropper>
		    </div>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="closeUploadup">取 消</el-button>
			<el-button @click="uploadadd">+</el-button>
			<el-button @click="uploaddown">-</el-button>
		    <el-button type="primary" @click="finishup">上传</el-button>
		  </div>
		</el-dialog>

	</div>
</template>

<script>
import wangEditor from 'wangeditor'
import OSS from 'ali-oss'
import qs from 'qs'
import {
  v4 as uuidv4
} from 'uuid'
import oss from '../../utils/oss'

export default {
  created () {
    // console.log("123")
    this.iid = window.sessionStorage.getItem('iid') * 1
    // 查询当前登录的机构信息
    this.getInstitution()
    // //console.log('%%%%%',this.updateRuleForm)
  },
  mounted () {
    // //console.log('asdasd',this.idescribes)
    var that = this
    // console.log('aaaaa',that.idescribes)
    // this.updateRuleForm.i_describes = th
  },
  data () {
    return {
      dialogVisibleupdate: false,
      iid: '',
      idescribes: '',
      formLabelWidth: '120px',
      updateImagedd: [],
      dialogImageUrl: '',
      updateRuleForm: {
        i_id: '',
        i_name: '',
        i_img: '',
        i_describes: ''
      },
      updateInDate: {
        iId: '',
        iName: '',
        iDescribes: '',
        iImg: ''
      },
      updateRules: {
        i_name: [{
          required: true,
          message: '请输入机构名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }
        ],
        i_describes: [{
          required: false,
          message: '请填写机构简介',
          trigger: 'blur'
        }],
        i_img: [{
          required: true,
          message: '请至少上传一张图片',
          trigger: 'blur'
        }]
      },
      selfShowup: false,
      selfoption: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      fileinfo: {}
    }
  },
  methods: {
    uploadadd () {
      this.$refs.cropper.changeScale(1)
    },
    uploaddown () {
      this.$refs.cropper.changeScale(-1)
    },

    closeUploadup () {
      console.log('关闭上传组件')
      this.selfShowup = false
      // this.updateImagedd.pop()
      if (this.updateImagedd.length > 0) {
        this.updateImagedd = this.updateImagedd.filter(function (v) {
          return v.url.indexOf('https://xuankechong') === 0
        })
        // console.log('筛选后的数组',this.imageArray)
        // 这里有点问题
        var aa = this.updateImagedd.map(function (v) {
          return v.url
        })
        this.updateRuleForm.i_img = aa.join(',')
      } else {
        this.updateImagedd = []
        this.updateRuleForm.i_img = ''
      }
      // if (this.updateImagedd.length !== 0) {
      // 	this.updateRuleForm.i_img = this.updateImagedd.join(",")
      // } else {
      // 	this.updateRuleForm.i_img = ''
      // }
      // this.updateImagedd = []
      // if (this.updateRuleForm.i_img.indexOf("http://")) {
      // 	this.updateRuleForm.i_img = ''
      // }
      // this.updateRuleForm.i_img = ''
    },
    finishup () {
      this.$refs.cropper.getCropBlob((data) => { // 获取当前裁剪好的数据
        const filee = new File([data], this.fileinfo.name, { type: this.fileinfo.type, lastModified: Date.now() })
        console.log('文件的信息', filee)
        oss.ossUploadFilee(filee).then(res => {
          // console.log(res.res.requestUrls)
          var urll = ''
          if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
            urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
          } else {
            urll = res.res.requestUrls[0]
          }
          console.log('qqqq', urll)
          const obj = {
            // url: res.res.requestUrls[0]
            url: urll
          }
          // 向image中添加图片
          // let obj = {
          // 	url: res.res.requestUrls[0]
          // }
          this.updateImagedd.push(obj)
          const aa = this.updateImagedd.map(v => v.url)
          this.updateRuleForm.i_img = aa.join(',')
          console.log('当前的照片', this.updateRuleForm.i_img)
          this.selfShowup = false
        })
        // 调用接口上传
        // upLoadFile(formData).then(result => {
        // 	console.log(result)
        // 	this.selfShow = false;
        // })
			 })
    },
    getUploadFileup (file, fileList) {
      var files = file.raw
      this.fileinfo = file // 保存下当前文件的一些基本信息
      const reader = new FileReader() // 创建文件读取对象
      reader.onload = async e => {
        let data
			 	if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.selfoption.img = data // 设置option的初始image
        this.selfShowup = true
      }
      reader.readAsArrayBuffer(files)
      this.selfoption.fixedNumber = [4, 3] // 图片的裁剪宽高比在这里也可以进行设置
    },
    submitUpdateForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateRuleForm.i_id = this.iid
          this.$http({
            url: 'company/sys-user/updateInstitution',
            method: 'POST',
            // header: {
            // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
            // },
            data: qs.stringify(
              this.updateRuleForm
            )
          }).then(res => {
            console.log(res)
            // 开始进行校区更改结果的判断操作
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
              // 更新缓存数据
              // if(this.updateRuleForm.is_pass === 2){
              // 	var campusNum = window.sessionStorage.getItem("campusNum") * 1
              // 	campusNum = campusNum - 1
              // 	window.sessionStorage.setItem("campusNum",campusNum)
              // }
            } else {
              this.$message.error(res.data.message)
            }
            // 进行弹出框的关闭操作
            // this.updateRuleForm.i_name = ''
            // this.updateRuleForm.cName = ''
            // this.updateRuleForm.i_img = ''
            // this.updateRuleForm.i_describes = ''
            // this.updateImagedd = []
            // this.dialogFormVisibleupdate = false
            // this.getCampusList()
          })
        } else {
          // //console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    getInstitution () {
      this.$http({
        url: 'company/sys-user/institutionById/' + this.iid,
        method: 'GET'
      }).then(res => {
        // console.log('qwe',res)
        // 开始机构信息的赋值操作
        this.updateRuleForm.i_id = res.data.data.iid
        this.updateRuleForm.i_name = res.data.data.iname
        this.updateRuleForm.i_img = res.data.data.iimg
        this.updateRuleForm.i_describes = res.data.data.idescribes
        this.idescribes = res.data.data.idescribes
        this.updateRuleForm.i_describes = res.data.data.idescribes
        // //console.log('^^^',this.idescribes)
        // //console.log('^^^',this.updateRuleForm.i_describes)
        // //console.log("$$$$",this.updateRuleForm)
        // //console.log("wqe",res.data.data.idescribes)
        // this.updateRuleForm.i_describes = res.data.data.idescribes
        // console.log('小米手机',this.updateRuleForm.i_describes)

        // 在这里进行图片数据的封装展示
        const imgArr = this.updateRuleForm.i_img.split(',')
        imgArr.forEach(item => {
          const obj = {
            url: item
          }
          this.updateImagedd.push(obj)
        })
      })
    },
    async ImgUploadRequestup (option) {
      // //console.log("进入上传方法")
      oss.ossUploadFile(option).then(res => {
        var urll = ''
        if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
          urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
        } else {
          urll = res.res.requestUrls[0]
        }
        console.log('qqqq', urll)
        const obj = {
          // url: res.res.requestUrls[0]
          url: urll
        }

        // 向image中添加图片

        this.updateImagedd.push(obj)
        const aa = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.i_img = aa.join()
        console.log('##', this.updateRuleForm.i_img)
      })
    },
    removeImage (file, fileList) {
    },
    removeImagebeforeup (file, fileList) {
      // //console.log(file.response.res.requestUrls[0])
      if (this.updateImagedd.length === 1) {
        this.$message.error('请至少保留一张图片')
        return false
      }
      // 获取删除文件的下标
      let img = this.updateImagedd.map(v => v.url)
      var Index = img.findIndex(item => item === file.url)
      // 1调用图片删除的api
      // //console.log('##',fileList)
      var fileName = file.url.substr(file.url.indexOf('/image'))
      // //console.log('$$',fileName)
      var isDeleted = oss.ossDeleteImage(fileName)

      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        this.updateImagedd.splice(Index, 1)
        img = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.i_img = img.join()
        // 进行数据库的删除操作
        this.deleteImage()
        // this.getCampusList()
        return true
      } else {
        this.$message.success('删除失败')
        return false
      }
    },
    loadSuccess (file, fileList) {
      // //console.log("上传成功！", file, fileList)
    },
    deleteImage () {
      this.$http({
        url: 'company/sys-user/updateImagesById',
        method: 'POST',
        // header: {
        // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
        // },
        data: qs.stringify({ id: this.updateRuleForm.i_id, img: this.updateRuleForm.i_img })
      }).then(res => {
        if (res.data.code === 200) {
          this.$message.success('删除成功')
        }
        return res.data
      })
    }
  },
  watch: {
    idescribes () {
      this.$nextTick(() => {
        // //console.log('ohhhhh',this.idescribes)
        this.updateRuleForm.i_describes = this.idescribes
        // //console.log('ohhhhh',this.updateRuleForm.i_describes)
        // //console.log("佛湾沚了")
        const editor = new wangEditor('#demo')
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
          // console.log("这",newHtml)
          this.updateRuleForm.i_describes = newHtml
          // //console.log('oggg',this.updateRuleForm.i_describes)
          // //console.log(this.updateRuleForm.i_describes)
        }

        const client = oss.createOssClient1()
        editor.config.customUploadImg = function (resultFiles, insertImgFn) {
          // resultFiles 是 input 中选中的文件列表
          // insertImgFn 是获取图片 url 后，插入到编辑器的方法
          client.put(uuidv4(), resultFiles[0])
            .then(function (res) {
              // console.log(res)
              // 上传图片，返回结果，将图片插入到编辑器中
              insertImgFn(res.url)
            }).catch(function (err) {
              // console.log(err)
            })
        }

        // 创建编辑器
        editor.create()
        // //console.log('asasas',aa.idescribes)
        editor.txt.html(this.updateRuleForm.i_describes)
        this.editor = editor
      })
    }
  }

}
</script>

<style >
/* 设置裁剪框样式 */
	.cropper-content {

	}
	.cropper {
	  width: auto;
	  height: 600px;
	}

	.jg-file-viewer{
		z-index: 9990 !important;
	}

	.w-e-toolbar {
	  z-index: 2!important;
	}
	.w-e-menu {
	  z-index: 2 !important;
	}
	.w-e-text-container {
	  z-index: 1 !important;
	}

</style>
