<template>

	<div>
		<el-button class="addButton" type="primary" size="medium" @click="addcoursebefore">新增课程</el-button>
		<!-- 校区列表展示 -->
		<el-table :data="courses" style="width: 100%" stripe border fit>
			<el-table-column label="课程名称">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span>{{ scope.row.co_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="课程大分类">
				<template slot-scope="scope">
					<span>{{ scope.row.cl_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="课程小分类">
				<template slot-scope="scope">
					<span>{{ scope.row.s_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="到店礼">
				<template slot-scope="scope">
					<!-- <i class="el-icon-time"></i> -->
					<span>{{ scope.row.co_gify }}</span>
				</template>
			</el-table-column>
			<el-table-column label="校区">
				<template slot-scope="scope">
					<span>{{ scope.row.c_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="课程价值(元)">
				<template slot-scope="scope">
					<span>{{ scope.row.co_price }}</span>
				</template>
			</el-table-column>
			<el-table-column label="课程价格(元)">
				<template slot-scope="scope">
					<span>{{ scope.row.co_prices }}</span>
				</template>
			</el-table-column>
			<el-table-column label="课程上下架状态">
				<template slot-scope="scope">
					<!-- <span style="margin-left: 10px">{{ scope.row.deleted === 0? "上架" : "下架" }}</span> -->
					<el-switch v-model="scope.row.deleted===0" active-color="#13ce66" inactive-color="#ff4949"
						@change="deletedChange(scope.row)">
					</el-switch>
				</template>
			</el-table-column>
			<el-table-column label="审核状态">
				<template slot-scope="scope">
					<span v-if="scope.row.is_pass === 1" style="color: burlywood;">审核中</span>
					<span v-if="scope.row.is_pass === 2" style="color: #42B983;">审核通过</span>
					<span v-if="scope.row.is_pass === 3" style="color: crimson;">审核不通过</span>
				</template>
			</el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<!-- <el-button v-if="scope.row.deleted === 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">下架</el-button>
				<el-button v-if="scope.row.deleted !== 0" size="mini" type="success"
					@click="handleEdit(scope.$index, scope.row)">上架</el-button> -->
					<!-- <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">详情</el-button> -->
					<el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
				</template>
			</el-table-column>
		</el-table>

		<!-- 添加课程对话框的相关代码 -->
		<el-dialog @opened="show()" @closed="hide()" title="新增课程" :visible.sync="dialogFormVisible" destroy-on-close>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="课程分类" prop="sid">
					<el-cascader :props="{ expandTrigger: 'hover' }" :options="options" v-model="ruleForm.sid"
						:show-all-levels="false"></el-cascader> &nbsp;&nbsp;&nbsp;&nbsp;
					<el-button type="primary" size="small" @click.once="addSubClassify"
						icon="el-icon-circle-plus-outline">添加小分类</el-button>
				</el-form-item>
				<el-form-item label="课程名称" prop="coName">
					<el-input v-model="ruleForm.coName"></el-input>
				</el-form-item>
				<el-form-item label="到店礼" prop="coGify">
					<el-input v-model="ruleForm.coGify" maxlength="10" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="校区" prop="cId">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="ruleForm.cId" placeholder="请选择校区">
						<el-option v-for="(item,index) in availableCampus" :key="item.c_id" :label="item.c_name"
							:value="item.c_id"></el-option>
						<!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				
				<el-form-item label="适合年级" prop="doagea">
					<el-select v-model="ruleForm.doagea" placeholder="请选择起始年龄" @change="changea">
						<el-option v-for="(item,index) in doageas" :key="index" :label="item"
							:value="item" :disabled="chooseIndex < index  && chooseIndex != 30"></el-option>
					</el-select>
					到
					<el-select v-model="ruleForm.doageb"  placeholder="请选择结束年龄" @change="changeb">
						<el-option v-for="(item,index) in doagebs" :key="index" :label="item"
							:value="item" :disabled="chooseIndex > index && chooseIndex != 30"></el-option>
					</el-select>
					岁
					<el-button @click="clearagea">清除</el-button>
				</el-form-item>
				
				<el-form-item label="适合年级" prop="doclass">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="ruleForm.doclass" placeholder="请选择年级">
						<el-option v-for="(item,index) in allClass" :key="item.id" :label="item.class"
							:value="item.id"></el-option>
						<!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				
				<el-form-item label="课程类别" prop="online">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="ruleForm.online" placeholder="请选择">
						<el-option v-for="(item,index) in online" :key="item.id" :label="item.line"
							:value="item.id"></el-option>
						<!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				
				<el-form-item label="课程价值" prop="coPrice">
					<div>
						<el-input-number size="large" v-model="ruleForm.coPrice" :precision="2" :step="0.1" :min="0"
							:max="3000"></el-input-number> 元
					</div>
					<span style="color: coral;">请参照全年班价格填写，课程价值需在合理范围内</span>
				</el-form-item>
				<el-form-item label="课程购买价格" prop="coPrice">
					<div>
						<el-input-number size="large" v-model="ruleForm.coPrices" :precision="2" :step="0.1" :min="0"
							:max="3000"></el-input-number> 元
					</div>
					<span style="color: coral;">请输入该课程包的引流价格，该价格需与线下引流价格一致</span>
				</el-form-item>
				<!-- 修改 -->
				<!-- <el-form-item label="课程数量" prop="listen">
					<el-input v-model="ruleForm.listen" placeholder="例如:共4次课 2小时/次"></el-input>
				</el-form-item> -->

				<el-form-item label="课程次数" prop="numbers">
					<!-- 共<el-input v-model="ruleForm.numbers" placeholder="例如:4"></el-input>次课 -->
					共<el-input-number size="small" v-model="ruleForm.numbers" :min="1" :max="1000"></el-input-number>次课
				</el-form-item>

				<el-form-item label="课程时长" prop="times">
					<!-- <el-input v-model="ruleForm.times" placeholder="例如:2"></el-input>小时/次 -->
					<el-input-number size="small" v-model="ruleForm.times" :min="1" :max="1000"></el-input-number>
					<el-select v-model="ruleForm.company" placeholder="请选择单位">
						<el-option v-for="item in Companys" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="课程大纲" prop="coContext">
					<el-input v-model="ruleForm.coContext" placeholder="请填写本次教学目标/目的/内容  例如: 少儿舞蹈基础与基本功训练"></el-input>
					<!-- <span style="color: coral;"></span> -->
				</el-form-item>
				<el-form-item label="课程展示图片" prop="image">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<!-- 之前的上传 -->
					<!-- <el-upload v-model="ruleForm.image" accept="image/*" ref="upload" :on-change="getUploadFile"
						:on-success="loadSuccess" :auto-upload="false" :before-remove="removeImagebefore"
						:on-remove="removeImage" :before-upload="Imgbeforupload" :http-request="ImgUploadRequest"
						list-type="picture-card" action="" :limit=1>
						<i class="el-icon-plus"></i>
					</el-upload> -->
					<!-- 新改的上传 -->
					<el-upload v-model="ruleForm.image" accept="image/*" ref="upload" :on-change="getUploadFile"
						:on-success="loadSuccess" :auto-upload="false" :before-remove="removeImagebefore"
						:on-remove="removeImage" :before-upload="Imgbeforupload" :file-list="imageArray"
						list-type="picture-card" action="" :limit=1>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<span style="color: coral;">机构品牌、课程品牌或Logo图片最佳,图片只能上传1张且尺寸比为1:1、小于3M的图片</span>
				</el-form-item>
				<el-form-item label="课程简介" prop="coInfo">
					<div id="demo1"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly v-model="ruleForm.coInfo"></textarea>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hide()">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick="3000">提 交</el-button>
			</div>
		</el-dialog>

		<!-- 修改课程对话框的相关代码 -->
		<el-dialog @opened="showup()" @closed="hideup()" title="修改课程" :visible.sync="dialogFormVisibleupdate"
			destroy-on-close>

			<el-form :model="updateRuleForm" :rules="updateRules" ref="updateRuleForm" label-width="120px"
				class="demo-ruleForm">
				<el-form-item label="课程分类" prop="sid">
					<el-cascader :props="{ expandTrigger: 'hover' }" :options="options" v-model="updateRuleForm.sid"
						:show-all-levels="false"></el-cascader> &nbsp;&nbsp;&nbsp;&nbsp;
					<el-button type="primary" size="small" @click="addSubClassify" icon="el-icon-circle-plus-outline">
						添加小分类</el-button>
				</el-form-item>
				<el-form-item label="课程名称" prop="coName">
					<el-input v-model="updateRuleForm.coName"></el-input>
				</el-form-item>
				<el-form-item label="到店礼" prop="coGify">
					<el-input v-model="updateRuleForm.coGify" maxlength="10" show-word-limit></el-input>
				</el-form-item>
				<el-form-item label="校区" prop="cId">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="updateRuleForm.cId" placeholder="请选择校区">
						<el-option v-for="(item,index) in availableCampus" :key="item.c_id" :label="item.c_name"
							:value="item.c_id"></el-option>
						<!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				
				<el-form-item label="适合年龄" prop="doagea">
					<el-select v-model="updateRuleForm.doagea" placeholder="请选择起始年龄" @change="changea">
						<el-option v-for="(item,index) in doageas" :key="index" :label="item"
							:value="item" :disabled="chooseIndex < index  && chooseIndex != 30"></el-option>
					</el-select>
					到
					<el-select v-model="updateRuleForm.doageb"  placeholder="请选择结束年龄" @change="changeb">
						<el-option v-for="(item,index) in doagebs" :key="index" :label="item"
							:value="item" :disabled="chooseIndex > index && chooseIndex != 30"></el-option>
					</el-select>
					岁
					<el-button @click="clearagea">清除</el-button>
				</el-form-item>
				
				<el-form-item label="适合年级" prop="doclass">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="updateRuleForm.doclass" placeholder="请选择年级">
						<el-option v-for="(item,index) in allClass" :key="item.id" :label="item.class"
							:value="item.id"></el-option>
						<!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				
				<el-form-item label="课程类别" prop="online">
					<!-- <el-input v-model="ruleForm.cId"></el-input> -->
					<el-select v-model="updateRuleForm.online" placeholder="请选择">
						<el-option v-for="(item,index) in online" :key="item.id" :label="item.line"
							:value="item.id"></el-option>
						<!-- <el-option label="区域二" value="beijing"></el-option> -->
					</el-select>
				</el-form-item>
				
				<el-form-item label="课程价值" prop="coPrice">
					<div>
						<el-input-number size="large" v-model="updateRuleForm.coPrice" :precision="2" :step="0.1"
							:min="0" :max="3000"></el-input-number> 元
					</div>
					<span style="color: coral;">请参照全年班价格填写，课程价值需在合理范围内</span>
				</el-form-item>
				<el-form-item label="课程购买价格" prop="coPrice">
					<div>
						<el-input-number size="large" v-model="updateRuleForm.coPrices" :precision="2" :step="0.1"
							:min="0" :max="3000"></el-input-number> 元
					</div>
					<span style="color: coral;">请输入该课程包的引流价格，该价格需与线下引流价格一致</span>
				</el-form-item>
				<!-- <el-form-item label="课程数量" prop="coName">
					<el-input v-model="updateRuleForm.listen" placeholder="例如:共4次课 2小时/次"></el-input>
				</el-form-item> -->

				<el-form-item label="课程次数" prop="numbers">
					<!-- 共<el-input v-model="ruleForm.numbers" placeholder="例如:4"></el-input>次课 -->
					共<el-input-number size="small" v-model="updateRuleForm.numbers" :min="1" :max="1000">
					</el-input-number>次课
				</el-form-item>

				<el-form-item label="课程时长" prop="times">
					<!-- <el-input v-model="ruleForm.times" placeholder="例如:2"></el-input>小时/次 -->
					<el-input-number size="small" v-model="updateRuleForm.times" :min="1" :max="1000"></el-input-number>
					<el-select v-model="updateRuleForm.company" placeholder="请选择单位">
						<el-option v-for="item in Companys" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="课程大纲" prop="coContext">
					<el-input v-model="updateRuleForm.coContext" placeholder="请填写本次教学目标/目的/内容  例如: 少儿舞蹈基础与基本功训练">
					</el-input>
					<!-- <span style="color: coral;"></span> -->
				</el-form-item>
				<el-form-item label="课程展示图片" prop="image">
					<!-- <el-input v-model="ruleForm.image"></el-input> -->
					<!-- <el-upload v-model="updateRuleForm.image" accept="image/*" ref="uploadup" :on-change="getUploadFileup" :on-success="loadSuccess"
						:auto-upload="false" :before-remove="removeImagebeforeup" :before-upload="Imgbeforuploadup" :on-remove="removeImage"
						:file-list="updateImagedd" :http-request="ImgUploadRequestup" list-type="picture-card" action=""
						:limit=1>
						<i class="el-icon-plus"></i>
					</el-upload> -->
					<!-- 新的上传并附加截图操作 -->
					<el-upload v-model="updateRuleForm.image" accept="image/*" ref="uploadup" :on-change="getUploadFileup"
						:on-success="loadSuccess" :auto-upload="false" :before-remove="removeImagebeforeup"
						:on-remove="removeImage" :before-upload="Imgbeforuploadup" :file-list="updateImagedd"
						list-type="picture-card" action="" :limit=1>
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisibleupdate">
						<!-- 在这里进已上传图片的遍历操作 -->
						<!-- <img v-for="img in updateImagedd"  width="100%" src="img" alt=""> -->
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>
					<span style="color: coral;">机构品牌、课程品牌或Logo图片最佳,图片只能上传1张且尺寸比为1:1、小于3M的图片</span>
				</el-form-item>
				<el-form-item label="课程简介" prop="coInfo">

					<div id="demo2"></div>
					<textarea name="" id="" cols="170" hidden rows="20" readonly
						v-model="updateRuleForm.coInfo"></textarea>

					<!-- <el-input v-model="ruleForm.describes"></el-input> -->
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideup()">取 消</el-button>
				<el-button type="primary" @click="submitUpdateForm('updateRuleForm')" v-preventReClick="3000">修 改
				</el-button>
			</div>
		</el-dialog>

		<!-- 添加分类对话框相关代码 -->
		<el-dialog @opened="showClassify()" @closed="hideClassify()" title="新增小分类" :visible.sync="dialogAddClassify"
			destroy-on-close>
			<el-form :model="ruleFormClassify" :rules="rulesClassify" ref="ruleFormClassify" label-width="100px"
				class="demo-ruleForm">
				<el-form-item label="课程大分类" prop="cId">
					<el-select v-model="ruleFormClassify.cId" placeholder="请选择大分类">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="课程小分类" prop="sName">
					<el-input v-model="ruleFormClassify.sName"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="hideClassify()">取 消</el-button>
				<el-button type="primary" @click="submitFormClassify('ruleFormClassify')">提 交</el-button>
			</div>
		</el-dialog>

		<div class="floot">
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.page" :page-sizes="[10, 15, 20, 25]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="Total">
			</el-pagination>
		</div>

		<!-- 上传图片裁切弹出层 -->
		<el-dialog title="图片剪裁" :visible.sync="selfShow" append-to-body width="40%" @close="closeUpload">
		 <div class="cropper-content">
		    <div class="cropper" style="text-align:center">
		      <vueCropper
		         ref="cropper"
		         :img="selfoption.img"
		         :outputSize="selfoption.size"
		         :outputType="selfoption.outputType"
		         :info="true"
		         :full="selfoption.full"
		         :canMove="selfoption.canMove"
		         :canMoveBox="selfoption.canMoveBox"
		         :original="selfoption.original"
		         :autoCrop="selfoption.autoCrop"
		         :fixed="selfoption.fixed"
		         :fixedNumber="selfoption.fixedNumber"
		         :centerBox="selfoption.centerBox"
		         :infoTrue="selfoption.infoTrue"
		         :fixedBox="selfoption.fixedBox"
		      ></vueCropper>
		    </div>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="closeUpload">取 消</el-button>
			<el-button @click="uploadadd">+</el-button>
			<el-button @click="uploaddown">-</el-button>
		    <el-button type="primary" @click="finish">上传</el-button>
		  </div>
		</el-dialog>

		<!-- 修改图片裁切弹出层 -->
		<el-dialog title="图片剪裁" :visible.sync="selfShowup" append-to-body width="40%" @close="closeUploadup">
		 <div class="cropper-content">
		    <div class="cropper" style="text-align:center">
		      <vueCropper
		         ref="cropper"
		         :img="selfoption.img"
		         :outputSize="selfoption.size"
		         :outputType="selfoption.outputType"
		         :info="true"
		         :full="selfoption.full"
		         :canMove="selfoption.canMove"
		         :canMoveBox="selfoption.canMoveBox"
		         :original="selfoption.original"
		         :autoCrop="selfoption.autoCrop"
		         :fixed="selfoption.fixed"
		         :fixedNumber="selfoption.fixedNumber"
		         :centerBox="selfoption.centerBox"
		         :infoTrue="selfoption.infoTrue"
		         :fixedBox="selfoption.fixedBox"
		      ></vueCropper>
		    </div>
		  </div>
		  <div slot="footer" class="dialog-footer">
		    <el-button @click="closeUploadup">取 消</el-button>
			<el-button @click="uploadadd">+</el-button>
			<el-button @click="uploaddown">-</el-button>
		    <el-button type="primary" @click="finishup">上传</el-button>
		  </div>
		</el-dialog>

	</div>
</template>

<script>
import wangEditor from 'wangeditor'
import OSS from 'ali-oss'
import qs from 'qs'
import {
  v4 as uuidv4
} from 'uuid'

import oss from '../../utils/oss'
import imgSize from '../../utils/imgSize.js'
export default {
  created () {
    this.iid = window.sessionStorage.getItem('iid') * 1
    this.queryInfo.i_id = this.iid
    this.getCoursesList()
    this.getAllClassify()
    this.availableAllCampus()
  },
  data () {
    return {
		// 1：2-3岁  2：4-6 3：7-9  4：10-12  5：13-15 6:15岁以上
	  	doageas:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"],
		doagebs:["0","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25"],
      allClass:[
		  {
			  id:1,
			  class:"学龄前"
		  },
		  {
			  id:2,
			  class:"幼儿园"
		  },
		  {
			  id:3,
			  class:"小学"
		  },
		  {
			  id:4,
			  class:"初中"
		  },
		  {
			  id:5,
			  class:"高中"
		  }
		  ],
	  online:[
		  {
			id: 1,
			line: "线下"
		  },
		  {
			id: 2,
			line: "线上"
		  },
		],
      courses: [],
      iid: '',
      campuses: [],
      Total: 0,
      tatalPage: 0,
      queryInfo: {
        c_id: 0, // 校区id
        co_name: '',
        i_id: 0, // 机构id
        pageSize: 10,
        page: 1,
        co_id: 0
      },
      value: false,
      deletedParam: {
        id: 0
      },
      dialogFormVisible: false,
      dialogFormVisibleupdate: false,
      formLabelWidth: '120px',
      ruleForm: {
        coName: '',
        coGify: '',
        cId: '',
        coPrice: 0,
        coInfo: '',
        iId: 0,
        sId: 0,
        sid: [],
        listen: '',
        image: '',
        coContext: '',
        coPrices: 0,
        numbers: 0,
        times: 0,
        company: '1',
		doclass: '',
		doagea:'',
		doageb:'',
		online: ''
      },
      updateRuleForm: {
        coId: '',
        coName: '',
        coGify: '',
        cId: '',
        coPrice: 0,
        coInfo: '',
        iId: 0,
        sId: 0,
        sid: [],
        listen: '',
        image: '',
        coContext: '',
        coPrices: 0,
        numbers: 0,
        times: 0,
        company: '1',
		doclass: '',
		doagea:'',
		doageb:'',
		online: ''
		
      },
      ruleFormClassify: {
        cId: '',
        sName: '',
        iid: 0
      },
      updateData: {
        cName: '',
        address: '',
        image: '',
        describes: '',
        isPass: 1,
        cId: 0
      },
      updateImageTemp: [], // 修改操作时新上传的图片文件暂存区
      updateImagedd: [], // 修改操作全部文件的暂存区
      rules: {
        coName: [{
          required: true,
          message: '请输入课程名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }
        ],
        coPrice: [{
          required: true,
          message: '请输入课程价值',
          trigger: 'blur'
        }],
        coPrices: [{
          required: true,
          message: '请输入课程购买价格',
          trigger: 'blur'
        }],
        coInfo: [{
          required: false,
          message: '请填写课程简介',
          trigger: 'blur'
        }],
        cId: [{
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        }],
		doclass: [{
		  required: true,
		  message: '请选择年级',
		  trigger: 'blur'
		}],
		online: [{
		  required: true,
		  message: '请选择课程类别',
		  trigger: 'blur'
		}],
        sid: [{
          required: true,
          message: '请选择课程分类',
          trigger: 'blur'
        }],
        // listen: [{
        // 	required: true,
        // 	message: '请填写课程数量',
        // 	trigger: 'blur'
        // }],
        numbers: [{
          required: true,
          message: '请填写课程次数',
          trigger: 'blur'
        }],
        times: [{
          required: true,
          message: '请填写课程时长',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传课程图片',
          trigger: 'blur'
        }],
        coContext: [{
          required: true,
          message: '请填写课程大纲',
          trigger: 'blur'
        }]
      },
      rulesClassify: {
        sName: [{
          required: true,
          message: '请输入小分类名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 2 到 20 个字符',
          trigger: 'blur'
        }
        ],
        cId: [{
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        }]
      },
      updateRules: {
        coName: [{
          required: true,
          message: '请输入课程名称',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 20,
          message: '长度在 3 到 20 个字符',
          trigger: 'blur'
        }
        ],
        coPrice: [{
          required: true,
          message: '请输入课程价值',
          trigger: 'blur'
        }],
        coPrices: [{
          required: true,
          message: '请输入课程购买价格',
          trigger: 'blur'
        }],
        coInfo: [{
          required: false,
          message: '请填写课程简介',
          trigger: 'blur'
        }],
        cId: [{
          required: true,
          message: '请选择校区',
          trigger: 'blur'
        }],
		doclass: [{
		  required: true,
		  message: '请选择年级',
		  trigger: 'blur'
		}],
		online: [{
		  required: true,
		  message: '请选择课程类别',
		  trigger: 'blur'
		}],
        sid: [{
          required: true,
          message: '请选择课程分类',
          trigger: 'blur'
        }],
        // listen: [{
        // 	required: true,
        // 	message: '请填写课程数量',
        // 	trigger: 'blur'
        // }],
        numbers: [{
          required: true,
          message: '请填写课程次数',
          trigger: 'blur'
        }],
        times: [{
          required: true,
          message: '请填写课程时长',
          trigger: 'blur'
        }],
        image: [{
          required: true,
          message: '请上传课程图片',
          trigger: 'blur'
        }],
        coContext: [{
          required: true,
          message: '请填写课程大纲',
          trigger: 'blur'
        }]
      },
      editor: null,
      dialogImageUrl: '', // 图片地址
      dialogVisible: false, // 是否允许图片
      dialogVisibleupdate: false,
      imageArray: [],
      updateImageArray: [],
      options: [],
      availableCampus: [],
      dialogAddClassify: false,
      Companys: [{
        value: '1',
        label: '小时/次'
      }, {
        value: '2',
        label: '分钟/次'
      }],
      selfoption: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        // fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: false, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      selfShow: false,
      fileinfo: {},
      selfShowup: false,
	  chooseIndex: 30
    }
  },
  methods: {
	  clearagea(){
		  this.ruleForm.doagea = ''
		  this.ruleForm.doageb = ''
		  this.chooseIndex = 30
	  },
	  changea(e){
		  console.log('a当前选中的值',e)
		  
		  if(this.ruleForm.doagea != ''){
			  console.log('a有值更改b的数组')
			  // 获取当前的a数组的下标
			  // var index = this.doageas.indexOf(e,0)
			  // console.log('当前数组出现的值的位置',index)
			  var index = this.getArreyIndex(e,this.doageas)
			  console.log('当前数组出现的值的位置',index)
			  this.chooseIndex = index
			  // 开始截取数组
			  
		  }
		  // 选中前面的值限制后面的值
		  
	  },
	  changeb(e){
	  	console.log('b当前选中的值',e)
		// 选中后面的值限制前面的值
		var index = this.getArreyIndex(e,this.doagebs)
		console.log('当前数组出现的值的位置',index)
		this.chooseIndex = index
	  },
	  getArreyIndex(string,arrey){
		 return arrey.indexOf(string,0)
	  },
    uploadadd () {
      this.$refs.cropper.changeScale(1)
    },
    uploaddown () {
      this.$refs.cropper.changeScale(-1)
    },
    closeUpload () {
      console.log('关闭上传组件')
      this.selfShow = false
      // this.imageArray = []
      // if(this.ruleForm.image.indexOf("https://")>0){
      // 	this.ruleForm.image = ''
      // }

      if (this.imageArray.length > 0) {
        this.imageArray = this.imageArray.filter(function (v) {
          return v.url.indexOf('https://xuankechong') === 0
        })
        // console.log('筛选后的数组',this.imageArray)
        // 这一步有问题
        var aa = this.imageArray.map(function (v) {
          return v.url
        })
        this.ruleForm.image = aa.join(',')
      } else {
        this.imageArray = []
        this.ruleForm.image = ''
      }
      // this.ruleForm.image = ''
    },
    closeUploadup () {
      console.log('关闭上传组件')
      this.selfShowup = false
      // this.updateImagedd = []
      // if (this.updateRuleForm.image.indexOf("https://xuankechong")<0) {
      // 	this.updateRuleForm.image = ''
      // }
      if (this.updateImagedd.length > 0) {
        this.updateImagedd = this.updateImagedd.filter(function (v) {
          return v.url.indexOf('https://xuankechong') === 0
        })
        // console.log('筛选后的数组',this.imageArray)
        // 这里有点问题
        var aa = this.updateImagedd.map(function (v) {
          return v.url
        })
        this.updateRuleForm.image = aa.join(',')
      } else {
        this.updateImagedd = []
        this.updateRuleForm.image = ''
      }
      // this.updateRuleForm.image = ''
    },
    finish () {
      this.$refs.cropper.getCropBlob((data) => { // 获取当前裁剪好的数据
        const filee = new File([data], this.fileinfo.name, { type: this.fileinfo.type, lastModified: Date.now() })
        console.log('文件的信息', filee)
        oss.ossUploadFilee(filee).then(res => {
          // console.log(res.res.requestUrls)
          var url = ''
          if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
            url = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
          } else {
            url = res.res.requestUrls[0]
          }
          const obj = {
            // url: res.res.requestUrls[0]
            url: url
          }
          this.imageArray.push(obj)
          const aa = this.imageArray.map(v => v.url)
          this.ruleForm.image = aa.join()
          console.log('%%%%%', this.ruleForm.image)
          this.selfShow = false
        })
        // 调用接口上传
        // upLoadFile(formData).then(result => {
        // 	console.log(result)
        // 	this.selfShow = false;
        // })
			 })
    },
    finishup () {
      this.$refs.cropper.getCropBlob((data) => { // 获取当前裁剪好的数据
        const filee = new File([data], this.fileinfo.name, { type: this.fileinfo.type, lastModified: Date.now() })
        console.log('文件的信息', filee)
        oss.ossUploadFilee(filee).then(res => {
          // console.log(res.res.requestUrls)
          var urll = ''
          if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
            urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
          } else {
            urll = res.res.requestUrls[0]
          }
          console.log('qqqq', urll)
          const obj = {
            // url: res.res.requestUrls[0]
            url: urll
          }
          this.updateImagedd.push(obj)
          const aa = this.updateImagedd.map(v => v.url)
          this.updateRuleForm.image = aa.join()
          console.log('##', this.updateRuleForm.image)
          this.selfShowup = false
        })
        // 调用接口上传
        // upLoadFile(formData).then(result => {
        // 	console.log(result)
        // 	this.selfShow = false;
        // })
			 })
    },
    getUploadFile (file, fileList) {
      var files = file.raw
      this.fileinfo = file // 保存下当前文件的一些基本信息
      const reader = new FileReader() // 创建文件读取对象
      reader.onload = async e => {
        let data
			 	if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.selfoption.img = data // 设置option的初始image
        this.selfShow = true
      }
      reader.readAsArrayBuffer(files)
      this.selfoption.fixedNumber = [1, 1] // 图片的裁剪宽高比在这里也可以进行设置
    },

    getUploadFileup (file, fileList) {
      var files = file.raw
      this.fileinfo = file // 保存下当前文件的一些基本信息
      const reader = new FileReader() // 创建文件读取对象
      reader.onload = async e => {
        let data
			 	if (typeof e.target.result === 'object') {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.selfoption.img = data // 设置option的初始image
        this.selfShowup = true
      }
      reader.readAsArrayBuffer(files)
      this.selfoption.fixedNumber = [1, 1] // 图片的裁剪宽高比在这里也可以进行设置
    },
    handleSizeChange (val) {
      // console.log(val);
      // 设置页面请求参数
      this.queryInfo.pageSize = val
      this.queryInfo.page = 1
      // 重新请求数据
      this.$http({
        url: 'company/course/allCourse',
        params: this.queryInfo
      }).then(res => {
        console.log(res)
        // 进行页面元素的赋值操作
        this.Total = res.data.data.Total
        this.tatalPage = res.data.data.tatalPage
        this.courses = res.data.data.courseList
      })
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.queryInfo.page = val
      this.$http({
        url: 'company/course/allCourse',
        params: this.queryInfo
      }).then(res => {
        console.log(res)
        // 进行页面元素的赋值操作
        this.Total = res.data.data.Total
        this.tatalPage = res.data.data.tatalPage
        this.courses = res.data.data.courseList
      })
    },
    availableAllCampus () {
      this.$http({
        url: 'company/campus/availableAllCampus',
        method: 'GET',
        params: {
          iid: this.iid
        }
      }).then(res => {
        // console.log("adasdas",res)
        this.availableCampus = res.data.data
      })
    },
    getAllClassify () {
      this.$http({
        url: 'company/subclassif/classifList',
        method: 'GET'
      }).then(res => {
        console.log('####', res)
        this.options = res.data.data
      })
    },
    addcoursebefore () {
      this.$http({
        url: 'company/campus/isPassNum',
        params: {
          i_id: this.iid
        }
      }).then(res => {
        console.log(res.data.data)
        if (res.data.data === 0) {
          this.$message.error('您还没有创建校区，或校区还没有审核通过，无法创建课程')
          this.dialogFormVisible = false
        } else {
          this.dialogFormVisible = true
        }
      })
      // console.log('gogogo')
    },
    async getCoursesList () {
      const {
        data
      } = await this.$http.get('company/course/allCourse', {
        params: this.queryInfo
      })
      console.log(data)
      if (data.code !== 200) {
        this.$message.error(data.message)
      } else {
        if (data.data.courseList.length === 0) {
          this.courses = []
        } else {
          this.courses = data.data.courseList
          this.Total = data.data.Total
          this.tatalPage = data.data.tatalPage
          // 查询驳回的数量
          // var aa = 0
          // data.data.courseList.map(item=>{
          // 	item.is_pass === 3
          // 	aa = aa + 1
          // })
          // window.sessionStorage.setItem("unMessageNum",aa)
        }
      }
    },
    // 点击修改按钮时的方法
    handleEdit (index, row) {
      console.log(index, row)
      // 在这里进行数据的赋值操作
      this.updateRuleForm.coName = row.co_name
      this.updateRuleForm.coGify = row.co_gify
      // 在这里进行数据的判断操作
      // 1重新打开页面的时候，就赋值updateImagedd里面的数值
      // 2第一次打开页面就赋值row里面的数值
      // if(row.image.length !== this.updateRuleForm.image.length){
      // 	// 第一次打开
      // 	this.updateRuleForm.image =this.updateRuleForm.image
      // }else{
      // 	this.updateRuleForm.image = row.image
      // }
      this.updateRuleForm.coId = row.co_id
      this.updateRuleForm.coPrice = row.co_price
      this.updateRuleForm.coPrices = row.co_prices
      this.updateRuleForm.coInfo = row.co_info
      this.updateRuleForm.cId = row.c_id
      this.updateRuleForm.i_id = row.i_id
      this.updateRuleForm.sId = row.s_id
      this.updateRuleForm.sid = [row.cl_id, row.s_id]
      this.updateRuleForm.listen = row.listen
      this.updateRuleForm.image = row.co_img
      this.updateRuleForm.coContext = row.co_context
	  this.updateRuleForm.doagea = row.doagea
	  this.updateRuleForm.doageb = row.doageb
	  this.updateRuleForm.doclass = row.doclass
	  this.updateRuleForm.online = row.online
	  this.chooseIndex = this.getArreyIndex(this.updateRuleForm.doagea,this.doageas)
      // this.updateRuleForm.numbers = row.listen
      const aa = row.listen.split(',')
      this.updateRuleForm.numbers = aa[0]
      this.updateRuleForm.times = aa[1]
      this.updateImagedd = []
      this.updateImagedd.push({
        url: row.co_img
      })
      this.updateRuleForm.company = row.company
      // 进行图片列表的封装操作
      // let imgArr = this.updateRuleForm.image.split(',')
      // imgArr.forEach(item => {
      // 	let obj = {
      // 		url: item
      // 	}
      // 	this.updateImagedd.push(obj)
      // })
      // 打开弹出框
      this.dialogFormVisibleupdate = true
    },
    handleDelete (index, row) {
      console.log(index, row)
    },
    async deletedChange (campusesInfo) {
      // console.log(campusesInfo)
      // false的时候，deleted为0    true的时候deleted为1
      // 进行商品的上下架操作
      this.deletedParam.id = campusesInfo.co_id
      console.log(this.deletedParam.id)
      if (campusesInfo.deleted === 0) {
        // 下架
        // 开始进行课程的参数的设置
        const {
          data
        } = await this.$http.post('company/course/deleteCourse', qs.stringify(this.deletedParam))
        // console.log(data)
        if (data.code !== 200) {
          return this.$message.error('操作失败，校区未成功下架')
        }
        campusesInfo.deleted = 1
        return this.$message.success('操作成功，校区已成功下架')
        // 这个时候修改元数组
      } else {
        // 上架
        const {
          data
        } = await this.$http.post('company/course/undeleteCourse', qs.stringify(this.deletedParam))
        // console.log(data)
        if (data.code !== 200) {
          return this.$message.error('操作失败，校区未成功上架')
        }
        campusesInfo.deleted = 0
        // campusesInfo.is_pass = 1
        return this.$message.success('操作成功，校区已成功上架')
        // 这个时候修改元数组
      }
    },
    submitForm (formName) {
		// console.log('乌拉拉',this.ruleForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
		  // 限制年龄区间的选择
		  if(this.ruleForm.doagea != ''){
			  if(this.ruleForm.doageb == ''){
				  this.$message.error('请选择结束年龄')
				  return
			  }
		  }
		  if(this.ruleForm.doageb != ''){
			  if(this.ruleForm.doagea == ''){
				  this.$message.error('请选择起始年龄')
				  return
			  }
		  }
          // 进行sid的数据充足操作
          this.ruleForm.listen = this.ruleForm.numbers + ',' + this.ruleForm.times
          this.ruleForm.sId = this.ruleForm.sid[1]
          this.$http({
            url: 'company/course/addCourse',
            method: 'POST',
            header: {
              'Content-Type': 'application/json;charset=utf-8' // 如果写成contentType会报错
            },
            data: {
              coName: this.ruleForm.coName,
              coGify: this.ruleForm.coGify,
              cId: this.ruleForm.cId,
              coPrice: this.ruleForm.coPrice,
              coPrices: this.ruleForm.coPrices,
              coInfo: this.ruleForm.coInfo,
              iId: this.iid,
              sId: this.ruleForm.sId,
              listen: this.ruleForm.listen,
              coImg: this.ruleForm.image,
              coContext: this.ruleForm.coContext,
              company: this.ruleForm.company,
			  doagea: this.ruleForm.doagea,
			  doageb: this.ruleForm.doageb,
			  doclass: this.ruleForm.doclass,
			  online: this.ruleForm.online
            }
          }).then(res => {
            // console.log(res.data)
            // 开始进行校区的判断操作
            if (res.data.code === 200) {
              this.$message.success('创建成功，审核通过后自动上架')
            } else {
              this.$message.error(res.data.message)
            }
            // 进行弹出框的关闭操作
            this.dialogFormVisible = false
            this.ruleForm.coName = ''
            this.ruleForm.coGify = ''
            this.ruleForm.cId = ''
            this.ruleForm.coPrice = 0
            this.ruleForm.coInfo = ''
            this.ruleForm.sid = []
            this.ruleForm.sId = 0
            this.ruleForm.listen = '',
            this.ruleForm.image = '',
            this.ruleForm.coContext = ''
            this.imageArray = []
			this.ruleForm.doagea = ''
			this.ruleForm.doageb = ''
			this.ruleForm.doclass = ''
			this.ruleForm.online = ''
			this.chooseIndex = 30
            this.getCoursesList()
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    submitFormClassify (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
			
          // 进行sid的数据充足操作
          this.$http({
            url: 'company/subclassif/addSubClassif',
            method: 'POST',
            // header: {
            // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
            // },
            data: qs.stringify({
              cId: this.ruleFormClassify.cId,
              sName: this.ruleFormClassify.sName
            })
          }).then(res => {
            // console.log(res.data)
            // 开始进行校区的判断操作
            if (res.data.code === 200) {
              this.$message.success(res.data.message)
            } else {
              this.$message.error(res.data.message)
            }
            // 进行弹出框的关闭操作
            this.dialogAddClassify = false
            this.ruleFormClassify.cId = ''
            this.ruleFormClassify.sName = ''
            this.getAllClassify()
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    submitUpdateForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 进行sid的数据充足操作
          this.updateRuleForm.listen = this.updateRuleForm.numbers + ',' + this.updateRuleForm.times
          this.updateRuleForm.sId = this.updateRuleForm.sid[1]
          this.$http({
            url: 'company/course/updateCourse',
            method: 'POST',
            header: {
              'Content-Type': 'application/json;charset=utf-8' // 如果写成contentType会报错
            },
            data: {
              coName: this.updateRuleForm.coName,
              coGify: this.updateRuleForm.coGify,
              cId: this.updateRuleForm.cId,
              coPrice: this.updateRuleForm.coPrice,
              coPrices: this.updateRuleForm.coPrices,
              coInfo: this.updateRuleForm.coInfo,
              iId: this.iid,
              sId: this.updateRuleForm.sId,
              coId: this.updateRuleForm.coId,
              coImg: this.updateRuleForm.image,
              listen: this.updateRuleForm.listen,
              coContext: this.updateRuleForm.coContext,
              company: this.updateRuleForm.company,
			  doagea: this.updateRuleForm.doagea,
			  doageb: this.updateRuleForm.doageb,
			  doclass: this.updateRuleForm.doclass,
			  online: this.updateRuleForm.online
            }
          }).then(res => {
            // console.log(res.data)
            // 开始进行校区的判断操作
            if (res.data.code === 200) {
              this.$message.success('修改成功，审核通过后自动上架')
            } else {
              this.$message.error(res.data.message)
            }
            // 进行弹出框的关闭操作
            this.dialogFormVisibleupdate = false
            this.updateRuleForm.coName = ''
            this.updateRuleForm.coGify = ''
            this.updateRuleForm.cId = ''
            this.updateRuleForm.coPrice = 0
            this.updateRuleForm.coInfo = ''
            this.updateRuleForm.sid = []
            this.updateRuleForm.sId = 0
            this.updateRuleForm.coId = ''
            this.updateRuleForm.image = ''
            this.updateRuleForm.listen = ''
            this.updateRuleForm.coContext = ''
			this.updateRuleForm.doagea = ''
			this.updateRuleForm.doageb = ''
			this.updateRuleForm.doclass = ''
			this.updateRuleForm.online = ''
			this.chooseIndex = 30
            this.getCoursesList()
          })
        } else {
          // console.log('error submit!!');
          this.$message.error('提交失败，请确认输入信息无误后，再次提交！')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    beforeDestroy () {
      // 调用销毁 API 对当前编辑器实例进行销毁
      this.editor.destroy()
      this.editor = null
    },
    getEditorData () {
      // 通过代码获取编辑器内容
      const data = this.editor.txt.html()
      // alert(data)
    },
    // 显示添加校区的富文本
    show () {
      const editor = new wangEditor('#demo1')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.ruleForm.coInfo = newHtml
      }

      const client = oss.createOssClient1()
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        client.put(uuidv4(), resultFiles[0])
          .then(function (res) {
            console.log(res)
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url)
          }).catch(function (err) {
            console.log(err)
          })
        // oss.ossUploadFile(uuidv4(), resultFiles[0])
        // 	.then(function(res) {
        // 		console.log(res)
        // 		// 上传图片，返回结果，将图片插入到编辑器中
        // 		insertImgFn(res.url)
        // 	}).catch(function(err) {
        // 		console.log(err)
        // 	})
      }

      // 创建编辑器
      editor.create()
      this.editor = editor
    },
    showClassify () {
      this.dialogAddClassify = true
    },
    hideClassify () {
      this.dialogAddClassify = false
      // 清空弹出层的所有数据
      this.ruleFormClassify.cId = ''
      this.ruleFormClassify.sName = ''
    },
    showup () {
      const editor = new wangEditor('#demo2')
      // 配置 onchange 回调函数，将数据同步到 vue 中
      editor.config.onchange = (newHtml) => {
        this.updateRuleForm.coInfo = newHtml
      }

      const client = oss.createOssClient1()
      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        client.put(uuidv4(), resultFiles[0])
          .then(function (res) {
            console.log(res)
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url)
          }).catch(function (err) {
            console.log(err)
          })
      }

      // 创建编辑器
      editor.create()
      editor.txt.html(this.updateRuleForm.coInfo)
      this.editor = editor
    },
    // 隐藏富文本
    hide () {
      // this.$message.success("asdsadsa")
      this.dialogFormVisible = false
      this.ruleForm.address = ''
      this.ruleForm.cName = ''
      this.ruleForm.image = ''
      this.ruleForm.describes = ''
      this.imageArray = []
    },
    hideup () {
      // this.$message.success("asdsadsa")
      this.updateRuleForm.coId = ''
      this.updateRuleForm.coName = ''
      this.updateRuleForm.coGify = ''
      this.updateRuleForm.coPrice = 0
      this.updateRuleForm.coInfo = ''
      this.updateRuleForm.sId = 0
      this.updateRuleForm.sid = []
      this.dialogFormVisibleupdate = false
      // 批量删除oss图片
      // this.updateImageTemp.forEach(item => {
      // 	var isDeleted = oss.ossDeleteImage(item.substr(item.indexOf('/image')))
      // 	if (isDeleted) {
      // 		console.log("删除成功")
      // 	} else {
      // 		console.log("删除失败")
      // 	}
      // })
      // this.updateImageTemp = []
    },
    Imgbeforupload (file) {
      var that = this
      // console.log("jinfangfa")
      // 进行图片类型和大小的限制
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jpg'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'JPG'
      const extension5 = testmsg === 'JPEG'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 3
      // const isLt2M = file.size / 1024  <= 500
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5) {
        // that.$message.error('图片的格式只能是 png、jpg、jpeg格式!')
        that.$message.error({
          message: '图片的格式只能是 png、jpg、jpeg格式!',
          duration: 6000
        })
        that.$refs.upload.clearFiles()
        return false // 必须加上return false; 才能阻止
      }
      if (!isLt2M) {
        // that.$message.error('图片大小不能超过3M!请重新选择!')
        that.$message.error({
          message: '图片大小不能超过3M!请重新选择!',
          duration: 6000
        })
        that.$refs.upload.clearFiles()
        return false // 必须加上return false; 才能阻止
      }
      const isSize = new Promise(function (resolve, reject) {
        const width = 200
        const height = 200
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          const valid = img.width / img.height == 1
          valid ? resolve() : reject()
          if (!valid) {
            that.$refs.upload.clearFiles()
          }
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        // that.$message.error('图片只能上传1:1尺寸的图片，请重新选择!')
        that.$message.error({
          message: '图片只能上传1:1尺寸的图片，请重新选择!',
          duration: 6000
        })
        that.$refs.upload.clearFiles()
        return Promise.reject()
        return false // 必须加上return false; 才能阻止
      })
      return extension || extension2 || extension3 || extension4 || extension5 && isLt2M && isSize
    },
    Imgbeforuploadup (file) {
      var that = this
      // console.log("jinfangfa")
      // 进行图片类型和大小的限制
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'png'
      const extension2 = testmsg === 'jpg'
      const extension3 = testmsg === 'jpeg'
      const extension4 = testmsg === 'JPG'
      const extension5 = testmsg === 'JPEG'
      // const extension4 = testmsg === 'gif'
      const isLt2M = file.size / 1024 / 1024 < 3
      // const isLt2M = file.size / 1024  <= 500
      if (!extension && !extension2 && !extension3 && !extension4 && !extension5) {
        that.$message.error('图片的格式只能是 png、jpg、jpeg格式!')
        that.$refs.uploadup.clearFiles()
        return false // 必须加上return false; 才能阻止
      }
      if (!isLt2M) {
        that.$message.error('图片大小不能超过3M!请重新选择!')
        that.$refs.uploadup.clearFiles()
        return false // 必须加上return false; 才能阻止
      }
      const isSize = new Promise(function (resolve, reject) {
        const width = 200
        const height = 200
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          const valid = img.width / img.height == 1
          valid ? resolve() : reject()
          if (!valid) {
            that.$refs.uploadup.clearFiles()
          }
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        that.$message.error('图片只能上传1:1尺寸的图片，请重新选择!')
        that.$refs.uploadup.clearFiles()
        return Promise.reject()
        return false // 必须加上return false; 才能阻止
      })
      return extension || extension2 || extension3 || extension4 || extension5 && isLt2M && isSize
    },
    async ImgUploadRequest (option) {
      console.log('进入上传方法', option)
      // console.log('option:------>',option)
      oss.ossUploadFile(option).then(res => {
        // console.log(res.res.requestUrls)
        var url = ''
        if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
          url = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
        } else {
          url = res.res.requestUrls[0]
        }
        const obj = {
          // url: res.res.requestUrls[0]
          url: url
        }
        this.imageArray.push(obj)
        const aa = this.imageArray.map(v => v.url)
        this.ruleForm.image = aa.join()
        console.log('%%%%%', this.ruleForm.image)
      })
    },
    async ImgUploadRequestup (option) {
      console.log('进入上传方法', option)
      oss.ossUploadFile(option).then(res => {
        // 向image中添加图片
        // console.log(res.res.requestUrls[0].indexOf('?uploadId'))
        var urll = ''
        if (res.res.requestUrls[0].indexOf('?uploadId') !== -1) {
          urll = res.res.requestUrls[0].slice(0, res.res.requestUrls[0].indexOf('?uploadId'))
        } else {
          urll = res.res.requestUrls[0]
        }
        console.log('qqqq', urll)
        const obj = {
          // url: res.res.requestUrls[0]
          url: urll
        }
        this.updateImagedd.push(obj)
        const aa = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.image = aa.join()
        console.log('##', this.updateRuleForm.image)
      })
    },
    removeImage (file, fileList) {},
    // 删除之前，对图片数据进行维护(添加校区)
    removeImagebefore (file, fileList) {
      // 获取文件的下标
      console.log(this.imageArray)
      // 获取删除文件的下标
      let img = this.imageArray.map(v => v.url)
      var Index = img.findIndex(item => item === file.url)
      // 1调用图片删除的api
      // console.log('##',fileList)
      var fileName = file.url.substr(file.url.indexOf('/image/'))
      // console.log('$$',fileName)
      var isDeleted = oss.ossDeleteImage(fileName)

      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        this.imageArray.splice(Index, 1)
        img = this.imageArray.map(v => v.url)
        this.ruleForm.image = img.join()
        // 进行数据库的删除操作
        this.deleteImage()
        return true
      } else {
        this.$message.success('删除失败')
        return false
      }
    },
    //  删除之前，对图片数据进行维护(修改校区)
    // 注意：修改校区的时候最后一张图片不能进行删除
    removeImagebeforeup (file, fileList) {
      console.log(this.updateImagedd)
      // 获取删除文件的下标
      let img = this.updateImagedd.map(v => v.url)
      var Index = img.findIndex(item => item === file.url)
      // 1调用图片删除的api
      // console.log('##',fileList)
      var fileName = file.url.substr(file.url.indexOf('/image/'))
      // console.log('$$',fileName)
      var isDeleted = oss.ossDeleteImage(fileName)

      if (isDeleted) {
        // 2清除图片的的对应的数组数据
        this.updateImagedd.splice(Index, 1)
        img = this.updateImagedd.map(v => v.url)
        this.updateRuleForm.image = img.join()
        // 进行数据库的删除操作
        this.deleteImage()
        return true
      } else {
        this.$message.success('删除失败')
        return false
      }
    },
    loadSuccess (file, fileList) {
      // console.log("上传成功！", file, fileList)
    },
    deleteImage () {
      this.$http({
        url: 'company/campus/updateImagesById',
        method: 'POST',
        // header: {
        // 	'Content-Type': 'application/json;charset=utf-8' //如果写成contentType会报错
        // },
        data: qs.stringify({
          id: this.updateRuleForm.cId,
          img: this.updateRuleForm.image
        })
      }).then(res => {
        if (res.data.code === 200) {
          this.$message.success('删除成功')
        }
        return res.data
      })
    },
    addSubClassify () {
      // console.log("点了添加小分类按钮")
      this.dialogAddClassify = true
      // 添加弹出层
    }

  },
  // 格式化日期
  filters: {
    formatTimer: function (value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m
    }
  }
}
</script>

<style scoped lang="less">
	.addButton {
		margin-bottom: 20px;
	}

	.home {
		width: 100%;
		margin-right: 20px;
		position: relative;
	}

	.floot {
		// position: fixed;
		// bottom: 40px;
		margin-top: 20px;

		// justify-content: center;
		.el-pagination {
			margin: 0 auto;
		}
	}

	// 设置裁剪框样式
	.cropper-content {
	    .cropper {
	      width: auto;
	      height: 600px;
	    }
	}

</style>
