<template>
	<div>
		<el-table :data="unlookSubscribe" style="width: 100%" stripe border fit>
			<el-table-column label="预约课程">
				<template slot-scope="scope">
					<i class="el-icon-time"></i>
					<span >{{ scope.row.co_name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="预约时间">
				<template slot-scope="scope">
					<span >{{ scope.row.create_time | formatTimer}}</span>
				</template>
			</el-table-column>
			<el-table-column label="家长昵称">
				<template slot-scope="scope">
					<span >{{ scope.row.nickName }}</span>
				</template>
			</el-table-column>
			<el-table-column label="家长手机号">
				<template slot-scope="scope">
					<!-- <span style="margin-left: 10px">{{ scope.row.deleted === 0? "上架" : "下架" }}</span> -->
					<!-- <el-switch v-model="scope.row.phone===1" active-color="#ff4949" inactive-color="#13ce66"
						@change="deletedChange(scope.row)">
					</el-switch> -->
					<span >{{ scope.row.phone }}</span>
				</template>
			</el-table-column>
			<el-table-column label="孩子的姓名">
				<template slot-scope="scope">
					<span >{{ scope.row.c_name}}</span>
				</template>
			</el-table-column>
			<el-table-column label="孩子的年级">
				<template slot-scope="scope">
					<span >{{ scope.row.c_class}}</span>
				</template>
			</el-table-column>
			<el-table-column label="孩子的性别">
				<template slot-scope="scope">
					<span >{{ scope.row.c_sex}}</span>
				</template>
			</el-table-column>
			<el-table-column label="孩子的年龄" >
				<template slot-scope="scope">
					<span >{{ scope.row.c_age}}</span>
				</template>
			</el-table-column>
		</el-table>
		<div class="floot">
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="querydata.page" :page-sizes="[10, 15, 20, 25]" :page-size="10"
				layout="total, sizes, prev, pager, next, jumper" :total="Total">
			</el-pagination>
		</div>

	</div>
</template>

<script>
import qs from 'qs'
export default {
  created () {
    // 获取session中的iid
    this.querydata.i_id = window.sessionStorage.getItem('iid')
    // console.log("执行created方法")
    this.$http({
      url: 'company/subscribe/lookedSubscribe',
      params: this.querydata
    }).then(res => {
      console.log(res)
      // 进行页面元素的赋值操作
      this.Total = res.data.data.Total
      this.tatalPage = res.data.data.tatalPage
      this.unlookSubscribe = res.data.data.unlookSubscribe
    })
  },
  data () {
    return {
      querydata: {
        page: 1, // 页码
        pageSize: 10, // 页面显示数量
        s_id: '', // 要想显示的预约
        i_id: '', // 机构的id
        state: '' // 预约的状态
      },
      Total: 0,
      tatalPage: 0,
      unlookSubscribe: []

    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(val);
      // 设置页面请求参数
      this.querydata.pageSize = val
      this.querydata.page = 1
      // 重新请求数据
      this.$http({
        url: 'company/subscribe/lookedSubscribe',
        params: this.querydata
      }).then(res => {
        console.log(res)
        // 进行页面元素的赋值操作
        this.Total = res.data.data.Total
        this.tatalPage = res.data.data.tatalPage
        this.unlookSubscribe = res.data.data.unlookSubscribe
      })
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`);
      this.querydata.page = val
      this.$http({
        url: 'company/subscribe/lookedSubscribe',
        params: this.querydata
      }).then(res => {
        console.log(res)
        // 进行页面元素的赋值操作
        this.Total = res.data.data.Total
        this.tatalPage = res.data.data.tatalPage
        this.unlookSubscribe = res.data.data.unlookSubscribe
      })
    }
  },
  // 格式化日期
  filters: {
    formatTimer: function (value) {
      const date = new Date(value)
      const y = date.getFullYear()
      let MM = date.getMonth() + 1
      MM = MM < 10 ? '0' + MM : MM
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let m = date.getMinutes()
      m = m < 10 ? '0' + m : m
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      return y + '-' + MM + '-' + d + ' ' + h + ':' + m
    }
  }
}
</script>

<style scoped lang="less">
	.floot {
		// position: fixed;
		// bottom: 40px;
		margin-top: 20px;

		// justify-content: center;
		.el-pagination {
			margin: 0 auto;
		}
	}
</style>
