<template>
	<div>
		<!-- 表单区域 -->
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px" class="demo-ruleForm">
					<el-form-item label="手机号" prop="phone">
						<el-input v-model="ruleForm.phone"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="sendCode()" v-preventReClick="3000">发送验证码</el-button>
					</el-form-item>
					<el-form-item label="密码" prop="code">
						<el-input v-model="ruleForm.code"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick="3000">立即修改密码</el-button>
					</el-form-item>
				</el-form>
			</div>
		</template>

<script>
export default {
  data () {
    return {
      ruleForm: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 15,
          message: '账号长度为11个字符的手机号',
          trigger: 'blur'
        }
        ],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        },
        {
          min: 3,
          max: 15,
          message: '验证码的长度为6位',
          trigger: 'blur'
        }
        ]
      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // alert('submit!');
          // 预验证通过，后访问后台数据
          const {
            data
          } = await this.$http.post('masagerLogin', qs.stringify(this.ruleForm))
          console.log(data)
          if (data.code !== 200) {
            return this.$message.error(data.message)
          }
          this.$message.success(data.message)
          // 存取token数据
          window.sessionStorage.setItem('satoken', data.data.tokenInfo.tokenValue)
          // 存取机构的id是否为会员，以及校区的数量
          window.sessionStorage.setItem('campusNum', data.data.campusNum)
          window.sessionStorage.setItem('iid', data.data.iid)
          window.sessionStorage.setItem('isIvip', data.data.isIvip)
          this.$router.push('/home')
        } else {
          this.$message.error('请您核对输入的账号、密码等信息')
          // console.log('error submit!!');
          return false
        }
      })
    },
    sendCode () {
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(this.ruleForm.phone)) {
        this.$message.error('请输入正确的手机号')
      } else {
        this.$message.success('手机号验证通过')
      }
    }
  }
}
</script>

		<style scoped>

		</style>
