<template>
	<div class="context">
		<!-- 表单区域 -->
		<span>您正在进行密码找回操作，请输入手机号，并接收到短信验证码后，将验证码填写到对应的输入框中，点击前往修改密码按钮，进行密码的修改操作!</span>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
			<el-form-item label="手机号" prop="phone">
				<el-input v-model="ruleForm.phone"></el-input>
				<!-- <el-button type="primary" @click="sendCode()">发送验证码</el-button> -->
			</el-form-item>
			<el-form-item>
				<el-button v-if="sendAuthCode" type="warning" @click="sendCode()">发送验证码</el-button>
				<el-button style="pointer-events: none;" v-if="!sendAuthCode" type="warning"><span style="color: #FFFFFF;">{{auth_time}} </span>
					秒之重新发送</el-button>
			</el-form-item>
			<el-form-item label="短信验证码" prop="code">
				<el-input v-model="ruleForm.code"></el-input>
			</el-form-item>
			<el-form-item label="新密码" prop="first">
				<el-input type="password" v-model="ruleForm.first"></el-input>
				<!-- <el-button type="primary" @click="sendCode()">发送验证码</el-button> -->
			</el-form-item>
			<el-form-item label="再次输入新密码" prop="second">
				<el-input type="password" v-model="ruleForm.second"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">修改密码</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import qs from 'qs'
export default {
  data () {
    return {
      ruleForm: {
        phone: '',
        code: '',
        first: '',
        second: ''
      },
      rules: {
        phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 15,
          message: '账号长度为11位的手机号',
          trigger: 'blur'
        }
        ],
        code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        },
        {
          min: 3,
          max: 15,
          message: '验证码的长度为6位',
          trigger: 'blur'
        }
        ],
        first: [{
          required: true,
          message: '请输入新密码',
          trigger: 'blur'
        },
        {
          pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
          message: '至少8个字符，至少1个大写字母，1个小写字母和1个数字,不能包含特殊字符（非数字字母）',
          trigger: 'blur'
        }
        ],
        second: [{
          required: true,
          message: '请再次输入新密码',
          trigger: 'change'
        },
        {
          pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
          message: '至少8个字符，至少1个大写字母，1个小写字母和1个数字,不能包含特殊字符（非数字字母）',
          trigger: 'blur'
        }
        ]
      },
      sendAuthCode: true,
      /* 布尔值，通过v-show控制显示‘获取按钮’还是‘倒计时’ */
      auth_time: 0
      /* 倒计时 计数器 */
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // alert('submit!');
          // 预验证通过，后访问后台数据
          if (this.ruleForm.first !== this.ruleForm.second) {
            this.$message.error('两次新密码输入不一致，请重新输入~')
            return
          }
          this.$http({
            url: 'checkCode',
            method: 'POST',
            data: qs.stringify({
              code: this.ruleForm.code,
              phone: this.ruleForm.phone,
              pwd: this.ruleForm.first
            })
          }).then(res => {
            console.log(res.data.code)
            if (res.data.code === 200) {
              this.$router.push('/login')
            } else {
              this.$message.error('验证码填写错误，请仔细核对验证码后，在点击 前往修改密码 按钮！')
            }
          })
        } else {
          this.$message.error('请您核对输入的账号、密码等信息')
          // console.log('error submit!!');
          return false
        }
      })
    },
    sendCode () {
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (!myreg.test(this.ruleForm.phone)) {
        this.$message.error('请输入正确的手机号')
      } else {
        // this.$message.success("手机号验证通过")
        // 进行密码的发送操作
        this.$http({
          url: 'smsCode',
          method: 'POST',
          data: qs.stringify({
            phone: this.ruleForm.phone
          })
        }).then(res => {
          console.log(res.data.code)
          if (res.data.code !== 200) {
            this.$message.error(res.data.message)
          } else {
            this.$message.success(res.data.message)
            this.sendAuthCode = false
            this.auth_time = 60
            var auth_timetimer = setInterval(() => {
              this.auth_time--
              if (this.auth_time <= 0) {
                this.sendAuthCode = true
                clearInterval(auth_timetimer)
              }
            }, 1000)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
	.context {
		width: 33%;
		height: 40%;
		margin: 0 auto;
		padding-top: 200px;
	}

	span {
		color: orangered;
	}

	.el-form {
		margin-top: 50px;
	}
</style>
